import React from "react";
import { Row, Col, Typography, Image } from "antd";
import BigLogo from "../../../public/logo_sherpa_big.svg";
import "./Content_main.css";

const { Title, Paragraph } = Typography;

const Content_main = () => {
  return (
    <div className="content">
      <Row
        className="content-block"
        justify="space-between"
        align="middle"
        gutter={20}
        style={{
          backgroundColor: "#fff",
          borderRadius: "2px",
          padding: "20px",
          boxSizing: "border-box",
        }}
      >
        <Col xs={24} md={12} className="text">
          <Title level={1}>
            Интеллектуальная логистика.
            <br />
            Прозрачные решения.
            <br />
            Быстрая доставка.
          </Title>
          <Paragraph className="description">
            Доставим всё, всегда, везде!
          </Paragraph>
        </Col>

        <Col xs={24} md={12} className="image">
          <Image src={BigLogo} alt="Контейнеры" preview={false} />
        </Col>
      </Row>
    </div>
  );
};

export default Content_main;
