import { observer } from "mobx-react-lite"
import { Button, Form, Input, Select, Row, Col, message } from "antd"
import InputMask from "react-input-mask"
import React from "react"
import counterAgentRequisitesStore from "../../store/CounterAgentRequisitesStore"
const { Option } = Select

const CounterAgentDetailsForm = observer(() => {
  const [form] = Form.useForm()

  const handleSubmit = async () => {
    try {
      console.log("Form values:", form.getFieldsValue())
      const values = await form.validateFields()
      await counterAgentRequisitesStore.submitForm()
    } catch (errorInfo) {
      console.log("Validate Failed:", errorInfo)
      message.error("Пожалуйста, заполните все обязательные поля.")
    }
  }

  return (
    <Form layout="vertical" form={form}>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item
            label="ИНН"
            name="inn"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите ИНН контрагента!",
              },
              {
                pattern: /^\d{10}$/,
                message: "ИНН должен содержать 10 цифр!",
              },
            ]}>
            <Input
              value={counterAgentRequisitesStore.inn}
              onChange={(e) =>
                counterAgentRequisitesStore.setField("inn", e.target.value)
              }
              placeholder="Введите ИНН контрагента"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Заполнить по ИНН" name="fillByInn">
            <Button>Заполнить по ИНН</Button>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Вид контрагента"
            name="typeCounterAgent"
            rules={[
              {
                required: false,
                message: "Пожалуйста, выберите вид контрагента!",
              },
            ]}>
            <Select
              value={counterAgentRequisitesStore.typeCounterAgent}
              onChange={(value) =>
                counterAgentRequisitesStore.setField("typeCounterAgent", value)
              }>
              <Option value="Юридическое лицо">Юридическое лицо</Option>
              <Option value="Физическое лицо">Физическое лицо</Option>
              <Option value="Юридическое лицо нерезидент">Юридическое лицо нерезидент</Option>
              <Option value="Физическое лицо нерезидент">Физическое лицо нерезидент</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: 12 }}>
        <Col span={8}>
          <Form.Item
            label="Наименование"
            name="name"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите наименование контрагента!",
              },
              {
                min: 3,
                message: "Наименование должно содержать минимум 3 символа!",
              },
            ]}
            style={{ gridColumn: "span 1" }}>
            <Input
              value={counterAgentRequisitesStore.name}
              onChange={(e) =>
                counterAgentRequisitesStore.setField("name", e.target.value)
              }
              placeholder="Введите наименование контрагента"
            />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            label="Полное наименование"
            name="fullName"
            rules={[]}
            style={{ gridColumn: "span 2" }}>
            <Input
              value={counterAgentRequisitesStore.fullName}
              onChange={(e) =>
                counterAgentRequisitesStore.setField("fullName", e.target.value)
              }
              placeholder="Введите полное наименование контрагента"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item
            label="КПП"
            name="kpp"
            rules={
              [
                // Удалить правило 'required: true'
              ]
            }>
            <Input
              value={counterAgentRequisitesStore.kpp}
              onChange={(e) =>
                counterAgentRequisitesStore.setField("kpp", e.target.value)
              }
              placeholder="Введите КПП контрагента"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Страна регистрации"
            name="country"
            rules={[
              {
                required: false,
                message: "Пожалуйста, выберите страну регистрации!",
              },
            ]}>
            <Select
              value={counterAgentRequisitesStore.country}
              onChange={(value) =>
                counterAgentRequisitesStore.setField("country", value)
              }>
              <Option value="РОССИЯ">РОССИЯ</Option>
              <Option value="ДРУГАЯ">ДРУГАЯ</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Регистрационный номер"
            name="regNumber"
            rules={
              [
                // Удалить правило 'required: true'
              ]
            }>
            <Input
              value={counterAgentRequisitesStore.regNumber}
              onChange={(e) =>
                counterAgentRequisitesStore.setField(
                  "regNumber",
                  e.target.value
                )
              }
              placeholder="Введите регистрационный номер"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: 12 }}>
        <Col span={8}>
          <Form.Item
            label="ОГРН"
            name="ogrn"
            rules={[
              {
                required: false,
                message: "Пожалуйста, введите ОГРН контрагента!",
              },
            ]}>
            <Input
              value={counterAgentRequisitesStore.ogrn}
              onChange={(e) =>
                counterAgentRequisitesStore.setField("ogrn", e.target.value)
              }
              placeholder="Введите ОГРН контрагента"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Контактный телефон"
            name="phone"
            rules={[
              {
                required: false,
                message: "Пожалуйста, введите номер телефона!",
              },
            ]}>
            <InputMask
              mask="+7 (999) 999-99-99"
              maskChar={null}
              value={counterAgentRequisitesStore.phone}
              onChange={(e) =>
                counterAgentRequisitesStore.setField("phone", e.target.value)
              }>
              {(inputProps) => (
                <Input {...inputProps} placeholder="Введите номер телефона" />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Адрес электронной почты"
            name="email"
            rules={[
              {
                required: false,
                message: "Пожалуйста, введите адрес электронной почты!",
              },
              {
                type: "email",
                message: "Неверный формат адреса электронной почты!",
              },
              {
                max: 50,
                message: "Электронная почта не должна превышать 50 символов!",
              },
            ]}>
            <Input
              value={counterAgentRequisitesStore.email}
              onChange={(e) =>
                counterAgentRequisitesStore.setField("email", e.target.value)
              }
              placeholder="Введите адрес Email"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Form.Item label="Руководитель" name="managerName">
            <Input
              value={counterAgentRequisitesStore.managerName}
              onChange={(e) =>
                counterAgentRequisitesStore.setField(
                  "managerName",
                  e.target.value
                )
              }
              placeholder="Введите ФИО руководителя"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Телефон руководителя"
            name="phoneManager"
            rules={[
              {
                required: false,
                message: "Пожалуйста, введите номер телефона!",
              },
            ]}>
            <InputMask
              mask="+7 (999) 999-99-99"
              maskChar={null}
              value={counterAgentRequisitesStore.managerPhone}
              onChange={(e) =>
                counterAgentRequisitesStore.setField(
                  "managerPhone",
                  e.target.value
                )
              }>
              {(inputProps) => (
                <Input {...inputProps} placeholder="Введите номер телефона" />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Электронная почта руководителя"
            name="emailManager"
            rules={[
              {
                type: "email",
                message: "Неверный формат адреса электронной почты!",
              },
            ]}>
            <Input
              value={counterAgentRequisitesStore.managerEmail}
              onChange={(e) =>
                counterAgentRequisitesStore.setField(
                  "managerEmail",
                  e.target.value
                )
              }
              placeholder="Введите адрес Email"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: 12 }}>
        <Col span={8}>
          <Form.Item label="Главный бухгалтер" name="accountantName">
            <Input
              value={counterAgentRequisitesStore.accountantName}
              onChange={(e) =>
                counterAgentRequisitesStore.setField(
                  "accountantName",
                  e.target.value
                )
              }
              placeholder="Введите ФИО главного бухгалтера"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Телефон главного бухгалтера"
            name="phoneAccountant"
            rules={[
              {
                required: false,
                message: "Пожалуйста, введите номер телефона!",
              },
            ]}>
            <InputMask
              mask="+7 (999) 999-99-99"
              maskChar={null}
              value={counterAgentRequisitesStore.accountantPhone}
              onChange={(e) =>
                counterAgentRequisitesStore.setField(
                  "accountantPhone",
                  e.target.value
                )
              }>
              {(inputProps) => (
                <Input {...inputProps} placeholder="Введите номер телефона" />
              )}
            </InputMask>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Электронная почта главного бухгалтера"
            name="emailAccountant"
            rules={[
              {
                type: "email",
                message: "Неверный формат адреса электронной почты!",
              },
            ]}>
            <Input
              value={counterAgentRequisitesStore.accountantEmail}
              onChange={(e) =>
                counterAgentRequisitesStore.setField(
                  "accountantEmail",
                  e.target.value
                )
              }
              placeholder="Введите адрес Email"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Form.Item
            label="Юридический адрес"
            name="legalAddress"
            rules={[
              {
                required: false,
                message: "Пожалуйста, введите юридический адрес!",
              },
            ]}>
            <Input
              value={counterAgentRequisitesStore.legalAddress}
              onChange={(e) =>
                counterAgentRequisitesStore.setField(
                  "legalAddress",
                  e.target.value
                )
              }
              placeholder="Введите юридический адрес предприятия"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ marginBottom: 12 }}>
        <Col span={24}>
          <Form.Item
            label="Фактический адрес"
            name="actualAddress"
            rules={[
              {
                required: false,
                message: "Пожалуйста, введите фактический адрес!",
              },
            ]}>
            <Input
              value={counterAgentRequisitesStore.actualAddress}
              onChange={(e) =>
                counterAgentRequisitesStore.setField(
                  "actualAddress",
                  e.target.value
                )
              }
              placeholder="Введите фактический адрес предприятия"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Button type="primary" onClick={handleSubmit}>
            Сохранить
          </Button>
        </Col>
      </Row>
    </Form>
  )
})

export default CounterAgentDetailsForm
