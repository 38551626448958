import React from "react";
import DeliveryInfoSection from "../../../../components/deliveryInfoSection/DeliveryInfoSection";
import DeliveryOptions from "../../../../components/deliveryOptions/DeliveryOptions";
import Breadcrumb from "../../../../UI/Breadcrumb";
import Button from "../../../../UI/Button";
import ShippingFormStore from "../../../../store/ShippingFormStore";

export const DeliveryRequest = () => {
  return (
    <div className="content-ship">
      <Breadcrumb
        links={[
          { href: "/", label: "Главная" },
          { href: "#", label: "Доставка" },
        ]}
      />
      <div className="shipping-form">
        <h2>ОЗНАКОМИТЬСЯ С ПРЕДЛОЖЕНИЯМИ ПО ДОСТАВКЕ</h2>
        <DeliveryInfoSection />
        <DeliveryOptions />
        <Button
          text="Назад"
          onClick={() => ShippingFormStore.setSubmitStatus(false)}
          className="submit-btn"
          containerClassName="back-btn-container"
        />
      </div>
    </div>
  );
};
