import { useState, useEffect } from "react";

const useDeliveryFilter = (initialOptions) => {
  const [options, setOptions] = useState(initialOptions);
  const [filters, setFilters] = useState({
    minCost: "",
    maxCost: "",
    minTime: "",
    maxTime: "",
  });
  const [sortOrder, setSortOrder] = useState({ field: null, isAsc: true }); // Отслеживаем поле сортировки и порядок

  // Функция для обновления начальных данных
  const setInitialOptions = (newOptions) => {
    if (JSON.stringify(newOptions) !== JSON.stringify(options)) {
      setOptions(newOptions);
      filterOptions(newOptions);
    }
  };

  const filterOptions = (optionsToFilter) => {
    const filtered = optionsToFilter.filter((option) => {
      const cost = parseInt(option.cost.replace("₽", "").trim(), 10);
      const time = parseInt(option.deliveryTime, 10);

      const isCostInRange =
        (!filters.minCost || cost >= filters.minCost) &&
        (!filters.maxCost || cost <= filters.maxCost);
      const isTimeInRange =
        (!filters.minTime || time >= filters.minTime) &&
        (!filters.maxTime || time <= filters.maxTime);

      return isCostInRange && isTimeInRange;
    });
    setOptions(filtered);
  };

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const resetFilters = () => {
    setFilters({
      minCost: "",
      maxCost: "",
      minTime: "",
      maxTime: "",
    });
    setOptions(initialOptions);
  };

  const sortOptions = (sortField) => {
    // Определяем порядок сортировки
    const isAsc =
      sortOrder.field === sortField ? !sortOrder.isAsc : true; // Меняем порядок, если поле то же самое
    setSortOrder({ field: sortField, isAsc });

    const sorted = [...options].sort((a, b) => {
      let comparison = 0;

      if (sortField === "cost") {
        const costA = parseInt(a.cost.replace("₽", "").trim(), 10);
        const costB = parseInt(b.cost.replace("₽", "").trim(), 10);
        comparison = costA - costB;
      } else if (sortField === "time") {
        const timeA = parseInt(a.deliveryTime, 10);
        const timeB = parseInt(b.deliveryTime, 10);
        comparison = timeA - timeB;
      } else if (sortField === "rating") {
        const ratingA = parseFloat(a.rating);
        const ratingB = parseFloat(b.rating);
        comparison = ratingB - ratingA; // По убыванию рейтинга
      }

      return isAsc ? comparison : -comparison; // Инвертируем порядок при убывании
    });

    setOptions(sorted);
  };

  useEffect(() => {
    filterOptions(options); // Применяем фильтрацию каждый раз, когда изменяются фильтры
  }, [filters]);

  return { options, sortOptions, handleFilterChange, resetFilters, filters, setInitialOptions };
};

export default useDeliveryFilter;