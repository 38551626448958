import React from 'react';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Modal,
} from 'antd';

const BankAccountsModal = ({visible, onCancel}) => {
  const [form] = Form.useForm();
  return (
      <Modal
          open={visible}
          title="Банковский счет"
          onCancel={onCancel}
          footer={null}
          width={560}
          height={500}
          transitionName=""

      >
        <Form
            form={form}
            initialValues={{
              variant: 'filled',
            }}
            layout={"vertical"}
            requiredMark={false}
        >

          <Form.Item
              label="Банк"
              name="Bank"
              rules={[
                {
                  required: true,
                  message: 'Пожалуйста, выберите банк!',
                },
              ]}
          >
            <Select
                options={[
                  {
                    value: 'Тинькофф',
                    label: 'Тинькофф',
                  },
                  {
                    value: 'Сбербанк',
                    label: 'Сбербанк',
                  },
                ]}
            />
          </Form.Item>
          <Form.Item label="Номер счета" name="accountNumber"
                     rules={[{required: true, message: 'Пожалуйста, введите номер счета!'}]}>
            <Input/>
          </Form.Item>

          <Form.Item label="Валюта" name="currency" rules={[{required: true, message: 'Пожалуйста, выберите валюту!'}]}>
            <Select
                options={[
                  {
                    value: 'USD',
                    label: 'USD',
                  },
                  {
                    value: 'EUR',
                    label: 'EUR',
                  },
                  {value: 'RUB', label: 'RUB'},
                ]}
            />
          </Form.Item>

          <div style={{display: "flex", justifyContent: "space-between", gap: "16px", width: "100%"}}>
            <Form.Item

                label="Дата открытия"
                name="openDate"
                rules={[
                  {
                    required: true,
                    message: 'Пожалуйста, укажите дату.',
                  },
                ]}
                style={{flex: "1"}}
            >
              <DatePicker style={{width: "100%"}}/>
            </Form.Item>
            <Form.Item
                label="Дата закрытия"
                name="closeDate"
                style={{flex: "1"}}
            >
              <DatePicker style={{width: "100%"}}/>
            </Form.Item>
          </div>


          <Form.Item>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Modal>
  );
};
export default BankAccountsModal;