import React, { useState } from "react";
import { Table, Input, Button, Space, Typography, Modal, Form } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { filteredData, validateData } from "../../features/DataSearcherUtils";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
const { Title } = Typography;

const WorldCountryDirectory = () => {
  const [searchText, setSearchText] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const data = [
    {
      key: 1,
      name: "Российская Федерация",
      fullName: "Russian Federation",
      alpha2: "RU",
      alpha3: "RUS",
    },
    {
      key: 2,
      name: "Соединенные Штаты Америки",
      fullName: "United States of America",
      alpha2: "US",
      alpha3: "USA",
    },
    {
      key: 3,
      name: "Германия",
      fullName: "Federal Republic of Germany",
      alpha2: "DE",
      alpha3: "DEU",
    },
    {
      key: 4,
      name: "Франция",
      fullName: "French Republic",
      alpha2: "FR",
      alpha3: "FRA",
    },
    {
      key: 5,
      name: "Япония",
      fullName: "Japan",
      alpha2: "JP",
      alpha3: "JPN",
    },
    {
      key: 6,
      name: "Китай",
      fullName: "People's Republic of China",
      alpha2: "CN",
      alpha3: "CHN",
    },
    {
      key: 7,
      name: "Великобритания",
      fullName: "United Kingdom of Great Britain and Northern Ireland",
      alpha2: "GB",
      alpha3: "GBR",
    },
    {
      key: 8,
      name: "Италия",
      fullName: "Italy",
      alpha2: "IT",
      alpha3: "ITA",
    },
    {
      key: 9,
      name: "Бразилия",
      fullName: "Federative Republic of Brazil",
      alpha2: "BR",
      alpha3: "BRA",
    },
    {
      key: 10,
      name: "Канада",
      fullName: "Canada",
      alpha2: "CA",
      alpha3: "CAN",
    },
    {
      key: 11,
      name: "Индия",
      fullName: "Republic of India",
      alpha2: "IN",
      alpha3: "IND",
    },
    {
      key: 12,
      name: "Австралия",
      fullName: "Commonwealth of Australia",
      alpha2: "AU",
      alpha3: "AUS",
    },
    {
      key: 13,
      name: "Испания",
      fullName: "Kingdom of Spain",
      alpha2: "ES",
      alpha3: "ESP",
    },
    {
      key: 14,
      name: "Мексика",
      fullName: "United Mexican States",
      alpha2: "MX",
      alpha3: "MEX",
    },
    {
      key: 15,
      name: "Южная Корея",
      fullName: "Republic of Korea",
      alpha2: "KR",
      alpha3: "KOR",
    },
    {
      key: 16,
      name: "Саудовская Аравия",
      fullName: "Kingdom of Saudi Arabia",
      alpha2: "SA",
      alpha3: "SAU",
    },
    {
      key: 17,
      name: "Нидерланды",
      fullName: "Kingdom of the Netherlands",
      alpha2: "NL",
      alpha3: "NLD",
    },
    {
      key: 18,
      name: "Швеция",
      fullName: "Kingdom of Sweden",
      alpha2: "SE",
      alpha3: "SWE",
    },
    {
      key: 19,
      name: "Швейцария",
      fullName: "Swiss Confederation",
      alpha2: "CH",
      alpha3: "CHE",
    },
    {
      key: 20,
      name: "Южная Африка",
      fullName: "Republic of South Africa",
      alpha2: "ZA",
      alpha3: "ZAF",
    },
  ];
  const columns = [
    {
      title: "Код",
      dataIndex: "key",
      key: "key",
      width: "10%",
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      width: "25%",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Международное наименование",
      dataIndex: "fullName",
      key: "fullName",
      width: "25%",
    },
    {
      title: "Альфа-2",
      dataIndex: "alpha2",
      key: "alpha2",
      width: "10%",
    },
    {
      title: "Альфа-3",
      dataIndex: "alpha3",
      key: "alpha3",
      width: "10%",
    },
    {
      title: "ЕАЭС",
      dataIndex: "eaes",
      key: "eaes",
      width: "10%",
    },
    {
      title: "Действия",
      key: "actions",
      width: 120,
      render: (_, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            style={{ backgroundColor: "transparent", border: "none" }}
          />
          <Button
            danger
            icon={<DeleteOutlined />}
            style={{ backgroundColor: "transparent", border: "none" }}
          />
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        // Логика добавления новой страны в data
        // Например, можно использовать setData, если data хранится в состоянии
        form.resetFields();
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="content-wrapper">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Title level={3}>СТРАНЫ МИРА</Title>
      </div>
      <Space style={{ marginBottom: 16 }}>
        <Input
          placeholder="Поиск по названию"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: "300px" }}
          prefix={<SearchOutlined />}
        />
        <Button type="primary" onClick={showModal}>
          Добавить
        </Button>
      </Space>

      <Table
        columns={columns}
        dataSource={filteredData(validateData(data), searchText)}
        bordered
        style={{ flexGrow: 1 }}
        tableLayout="fixed"
        pagination={false}
        scroll={{ y: 440 }}
      />

      <Modal
        title="Добавить новую страну"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical" name="form_in_modal">
          <Form.Item
            name="name"
            label="Наименование"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите наименование страны!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="fullName"
            label="Международное наименование"
            rules={[
              {
                required: true,
                message:
                  "Пожалуйста, введите международное наименование страны!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="alpha2"
            label="Альфа-2"
            rules={[
              { required: true, message: "Пожалуйста, введите Альфа-2 код!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="alpha3"
            label="Альфа-3"
            rules={[
              { required: true, message: "Пожалуйста, введите Альфа-3 код!" },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default WorldCountryDirectory;
