const SelectField = ({
  label,
  id,
  value,
  onChange,
  name,
  options,
  disabled,
  selectorName = "selector",
}) => {
  return (
    <div className={selectorName}>
      <label htmlFor={id}>{label}</label>
      <select
        className={id}
        id={id}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
      >
        <option value="" className="hidden-option">
          {name}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectField;
