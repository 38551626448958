import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Form, Row, Col, Select } from "antd";
import { useGeoSearcher } from "../../../features/useGeoSearcher";
import my_ShippingFormStore from "../../../store/ShippingFormStore";

const { Option } = Select;

const RouteBlock = observer(
  ({
    title,
    country,
    setCountry,
    city,
    setCity,
    adressName = "",
    setAdressName,
    setAdress,
  }) => {
    const { searcher, parentsCity } = useGeoSearcher({
      setAdress,
      setAdressName,
      adressName,
    });

    useEffect(() => {
      if (!my_ShippingFormStore.city) {
        my_ShippingFormStore.setField("city", parentsCity);
      }
    }, [parentsCity]);

    return (
      <>
        <h4>{title}:</h4>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Страна">
              <Select
                value={country ? country : "Россия"}
                onChange={setCountry}
              >
                <Option value="Россия">Россия</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Город">
              <Select value={city ? city : parentsCity} onChange={setCity}>
                {parentsCity && (
                  <Option value={parentsCity}>{parentsCity}</Option>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Адрес">{searcher}</Form.Item>
      </>
    );
  }
);

export default RouteBlock;
