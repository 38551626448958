import React, { useState, useEffect, useCallback, useRef } from "react"
import {
  Table,
  Input,
  Button,
  Space,
  Typography,
  Spin,
  Modal,
  Form,
  message,
} from "antd"
import { SearchOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons"
import { useDrag, useDrop } from "react-dnd"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import axios_instance from "../../requests/config_defaul"
import folder from "../../public/icn_folder.svg"
import chevron from "../../public/icn_chevron.svg"
import { ItemTypes } from "../counterAgentsTable/ItemTypes"
import BanksDirectiryCreateModal from "../banksDirectiryCreateModal/BanksDirectiryCreateModal"
import BankGroupCreateModal from "./BankGroupCreateModal"

const { Title } = Typography

const DraggableRow = ({ index, moveRow, children, ...restProps }) => {
  const ref = useRef(null)
  const [itemState, setItemState] = useState({ ...restProps })

  useEffect(() => {
    setItemState((prevItemState) => {
      if (prevItemState.parent_id !== restProps.parent_id) {
        return { ...restProps }
      }
      return prevItemState
    })
  }, [restProps.parent_id])

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: ItemTypes.BANK,
    item: () => ({ index, ...itemState, isBank: !itemState.isGroup }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => !itemState.isGroup,
  }))

  const [{ isDraggingGroup }, groupDrag] = useDrag(() => ({
    type: ItemTypes.GROUP,
    item: () => ({ index, ...itemState, isGroup: true }),
    collect: (monitor) => ({
      isDraggingGroup: monitor.isDragging(),
    }),
    canDrag: () => itemState.isGroup,
  }))

  const [, drop] = useDrop(() => ({
    accept: [ItemTypes.BANK, ItemTypes.GROUP],
    hover: (item, monitor) => {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const monitorItem = monitor.getItem()

      if (
        itemState.isGroup &&
        monitorItem.group_id !== itemState.id &&
        monitor.isOver({ shallow: true })
      ) {
        return
      }

      if (typeof dragIndex !== "number" || typeof hoverIndex !== "number")
        return

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      if (typeof item.moveRow === "function") {
        item.moveRow(dragIndex, hoverIndex)
      }
      item.index = hoverIndex
    },
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop()
      const targetGroup = itemState

      console.log("Inside DraggableRow drop - calling handleDrop")
      if (didDrop || !targetGroup.isGroup) {
        return
      }
      restProps.handleDrop(item, targetGroup)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  }))

  const [, groupDrop] = useDrop(() => ({
    accept: ItemTypes.GROUP,
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop()
      const targetGroup = itemState

      console.log("Inside DraggableRow groupDrop - calling handleDrop")
      if (didDrop || !targetGroup.isGroup) {
        return
      }
      restProps.handleDrop(item, targetGroup)
    },
    collect: (monitor) => ({
      isOverGroup: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  }))

  const combinedRef = (node) => {
    ref.current = node
    drag(node)
    groupDrag(node)
    drop(node)
    groupDrop(node)
  }

  return (
    <tr
      ref={combinedRef}
      key={itemState.id}
      style={{
        cursor: "move",
        opacity: isDragging || isDraggingGroup ? 0.5 : 1,
        backgroundColor:
          groupDrop[0]?.isOverGroup && groupDrop[0].canDrop
            ? "lightgreen"
            : "inherit",
      }}
      {...restProps}>
      {children}
    </tr>
  )
}

// Компонент BanksDirectory
const BanksDirectory = () => {
  const [searchText, setSearchText] = useState("")
  const [isBankModalVisible, setIsBankModalVisible] = useState(false)
  const [isGroupModalVisible, setIsGroupModalVisible] = useState(false)
  const [isCreateGroupModalVisible, setIsCreateGroupModalVisible] =
    useState(false)
  const [editingBank, setEditingBank] = useState(null)
  const [editingGroup, setEditingGroup] = useState(null)
  const [groups, setGroups] = useState([])
  const [loading, setLoading] = useState(false)
  const [expandedKeys, setExpandedKeys] = useState([])
  const [noGroupBanks, setNoGroupBanks] = useState([])
  const [groupBanks, setGroupBanks] = useState({})
  const [banksWithSearch, setBanksWithSearch] = useState([])

  const fetchBanksWithoutGroup = useCallback(async () => {
    setLoading(true)
    try {
      const response = await axios_instance.get("/counterparty/banks/get/all", {
        params: { group_id: "" },
      })
      setNoGroupBanks(response.data)
    } catch (error) {
      console.error("Error fetching banks without group:", error)
    } finally {
      setLoading(false)
    }
  }, [])

  const fetchBanksWithSearch = useCallback(async (searchQuery) => {
    setLoading(true)
    try {
      const params = { search: searchQuery }
      if (!searchQuery) {
        return
      }
      const response = await axios_instance.get("/counterparty/banks/get/all", {
        params,
      })

      // Фильтрация, как в ConterAgentsTable
      const filteredBanks = response.data.filter((bank) => {
        const searchLower = searchQuery.toLowerCase()
        return (
          bank.bank_name.toLowerCase().includes(searchLower) ||
          (bank.bank_biscc &&
            bank.bank_biscc.toLowerCase().includes(searchLower)) ||
          (bank.corr_account &&
            bank.corr_account.toLowerCase().includes(searchLower)) ||
          (bank.address && bank.address.toLowerCase().includes(searchLower)) ||
          (bank.phone_number &&
            bank.phone_number.toLowerCase().includes(searchLower))
        )
      })

      // При поиске нужно отображать только отфильтрованные банки,
      // поэтому очищаем состояние, связанное с группами и банками без групп.
      setGroupBanks({})
      setBanksWithSearch(filteredBanks)
      setExpandedKeys([])
      setNoGroupBanks([]) // Очищаем, так как при поиске группы не нужны
    } catch (error) {
      console.error("Error fetching banks with search:", error)
    } finally {
      setLoading(false)
    }
  }, [])

  const fetchBanksByGroup = useCallback(async (groupId) => {
    setLoading(true)
    try {
      const response = await axios_instance.get("/counterparty/banks/get/all", {
        params: { group_id: groupId },
      })

      setGroupBanks((prevGroupBanks) => ({
        ...prevGroupBanks,
        [groupId]: response.data,
      }))
    } catch (error) {
      console.error("Error fetching banks for group:", error)
    } finally {
      setLoading(false)
    }
  }, [])

  const fetchGroups = useCallback(async () => {
    setLoading(true)
    try {
      const response = await axios_instance.get("/counterparty/banks/group/all")
      setGroups(response.data)
    } catch (error) {
      console.error("Error fetching groups:", error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (searchText) {
        fetchBanksWithSearch(searchText)
      } else {
        // При сбросе поиска возвращаем обычное отображение
        setBanksWithSearch([])
        fetchBanksWithoutGroup()
        fetchGroups()
      }
    }, 500)

    return () => clearTimeout(timeOutId)
  }, [searchText, fetchBanksWithSearch, fetchBanksWithoutGroup, fetchGroups])

  const findGroup = (groupId, groups) => {
    for (const group of groups) {
      if (group.id === groupId) {
        return group
      }
      if (group.children) {
        const found = findGroup(groupId, group.children)
        if (found) return found
      }
    }
    return null
  }

  const onExpand = (expanded, record) => {
    const key = record.key
    if (expanded) {
      if (!groupBanks[key]) {
        fetchBanksByGroup(key)
      }
      setExpandedKeys((prevExpandedKeys) => [...prevExpandedKeys, key])
    } else {
      setExpandedKeys((prevExpandedKeys) =>
        prevExpandedKeys.filter((k) => k !== key)
      )
    }
  }

  const prepareDataForTable = (
    groups,
    expandedKeys,
    searchText,
    noGroupBanks,
    groupBanks,
    banksWithSearch
  ) => {
    const data = []

    const mapBank = (bank) => ({
      key: `bank-${bank.id}`,
      id: bank.id,
      name: bank.bank_name,
      bik: bank.bank_biscc,
      coordAccount: bank.corr_account,
      address: bank.address,
      phones: bank.phone_number,
      group_id: bank?.group_id,
      isBank: true,
      ...bank,
    })

    if (searchText) {
      // При поиске отображаем только найденные банки
      banksWithSearch.forEach((bank) => {
        data.push(mapBank(bank))
      })
    } else {
      // При обычном отображении строим иерархию
      const mapGroup = (group) => {
        const mappedGroup = {
          key: group.id,
          name: group.group_name,
          children: [],
          isGroup: true,
          id: group.id,
          parent_id: group.parent_id,
        }

        if (
          expandedKeys.includes(group.id) ||
          isDescendantOfExpanded(group, expandedKeys, groups)
        ) {
          const banks = groupBanks[group.id] || []
          banks.forEach((bank) => {
            mappedGroup.children.push(mapBank(bank))
          })
        }

        if (group.children) {
          group.children.forEach((childGroup) => {
            mappedGroup.children.push(mapGroup(childGroup))
          })
        }

        return mappedGroup
      }

      const isDescendantOfExpanded = (group, expandedKeys, allGroups) => {
        if (!group.parent_id) return false
        if (expandedKeys.includes(group.parent_id)) return true
        const parentGroup = findGroup(group.parent_id, allGroups)
        return parentGroup
          ? isDescendantOfExpanded(parentGroup, expandedKeys, allGroups)
          : false
      }

      groups
        .filter((group) => group.parent_id === null)
        .forEach((group) => {
          data.push(mapGroup(group))
        })

      noGroupBanks.forEach((bank) => {
        data.push(mapBank(bank))
      })
    }

    // Сортировка данных
    const sortData = (items) => {
      return items.map((item) => ({
        ...item,
        children: item.children
          ? sortData(item.children).sort((a, b) => {
              if (a.name && b.name) {
                return a.name.localeCompare(b.name)
              } else if (a.name) {
                return -1
              } else if (b.name) {
                return 1
              } else {
                return 0
              }
            })
          : [],
      }))
    }

    return sortData(data)
  }

  const moveRow = useCallback((dragIndex, hoverIndex) => {}, [])

  useEffect(() => {
    if (!searchText) {
      fetchGroups()
      fetchBanksWithoutGroup()
    }
  }, [fetchGroups, fetchBanksWithoutGroup, searchText])

  const handleDeleteBank = useCallback(
    (record) => {
      Modal.confirm({
        title: "Удаление банка",
        content: `Вы уверены, что хотите удалить банк "${record.name}"?`,
        okText: "Да",
        okType: "danger",
        cancelText: "Нет",
        onOk: async () => {
          try {
            setLoading(true)
            const response = await axios_instance.delete(
              `/counterparty/banks/delete/${record.id}`
            )
            if (response.status === 200) {
              console.log(`Bank ${record.id} deleted successfully`)
              if (searchText) {
                await fetchBanksWithSearch(searchText)
              } else {
                await fetchBanksWithoutGroup()
                if (record.group_id) {
                  setGroupBanks((prev) => {
                    const updatedGroupBanks = { ...prev }
                    updatedGroupBanks[record.group_id] = updatedGroupBanks[
                      record.group_id
                    ]?.filter((bank) => bank.id !== record.id)
                    return updatedGroupBanks
                  })
                }
              }
            } else {
              console.error(
                `Error deleting bank ${record.id}: Server returned status ${response.status}`
              )
            }
          } catch (error) {
            console.error(
              `Error deleting bank ${record.id}:`,
              error.response?.status,
              error.response?.data,
              error.message
            )
          } finally {
            setLoading(false)
          }
        },
      })
    },
    [fetchBanksWithSearch, fetchBanksWithoutGroup, searchText, setGroupBanks]
  )

  const handleDeleteGroup = useCallback(
    (record) => {
      Modal.confirm({
        title: "Удаление группы",
        content: `Вы уверены, что хотите удалить группу "${record.name}"?`,
        okText: "Да",
        okType: "danger",
        cancelText: "Нет",
        onOk: async () => {
          try {
            setLoading(true)
            const response = await axios_instance.delete(
              `/counterparty/banks/group/delete/${record.id}`
            )
            if (response.status === 200) {
              console.log(`Group ${record.id} deleted successfully`)
              await fetchGroups()
              await fetchBanksWithoutGroup()
              setGroupBanks((prev) => {
                const updated = { ...prev }
                delete updated[record.id]
                return updated
              })
            } else {
              console.error(
                `Error deleting group ${record.id}: Server returned status ${response.status}`
              )
            }
          } catch (error) {
            console.error(
              `Error deleting group ${record.id}:`,
              error.response?.status,
              error.response?.data,
              error.message
            )
          } finally {
            setLoading(false)
          }
        },
      })
    },
    [fetchGroups, fetchBanksWithoutGroup, setGroupBanks]
  )

  const handleDelete = useCallback(
    (record) => {
      if (record.isGroup) {
        handleDeleteGroup(record)
      } else {
        handleDeleteBank(record)
      }
    },
    [handleDeleteBank, handleDeleteGroup]
  )

  const handleEdit = (record) => {
    if (record.isGroup) {
      handleEditGroup(record)
    } else {
      handleEditBank(record)
    }
  }

  const handleEditBank = (record) => {
    setEditingBank(record)
    setIsBankModalVisible(true)
  }

  const handleEditGroup = (record) => {
    setEditingGroup(record)
    setIsGroupModalVisible(true)
  }

  const handleEditGroupModalOk = async () => {
    try {
      setLoading(true)
      const response = await axios_instance.put(
        `/counterparty/banks/group/update/${editingGroup.id}`,
        { group_name: editingGroup.name }
      )
      if (response.status === 200) {
        message.success(`Группа ${editingGroup.name} успешно обновлена`)
        await fetchGroups()
        await fetchBanksWithoutGroup()
        setIsGroupModalVisible(false)
        setEditingGroup(null)
      } else {
        message.error("Ошибка при обновлении группы.")
      }
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response
        if (status === 400) {
          if (data.detail === "Название группы не может быть пустым") {
            message.error("Название группы не может быть пустым")
          } else if (
            data.detail ===
            "Название группы слишком длинное (максимальная длина - 255 символов)"
          ) {
            message.error(
              "Название группы слишком длинное (максимальная длина - 255 символов)"
            )
          } else if (
            data.detail === "Группа с таким названием уже существует"
          ) {
            message.error("Группа с таким названием уже существует")
          } else {
            message.error(data.detail)
          }
        } else if (status === 500) {
          message.error(
            "Произошла ошибка при обновлении группы. Пожалуйста, попробуйте позже. Обратитесь к администратору, если ошибка повторяется."
          )
        } else {
          message.error("Неизвестная ошибка. Пожалуйста, попробуйте позже.")
        }
      } else {
        message.error("Ошибка при обновлении группы.")
      }
      console.error("Error updating group:", error)
    } finally {
      setLoading(false)
    }
  }

  const handleEditBankModalCancel = () => {
    setIsBankModalVisible(false)
    setEditingBank(null)
  }

  const handleEditGroupModalCancel = () => {
    setIsGroupModalVisible(false)
    setEditingGroup(null)
  }

  const handleEditBankInputChange = (field, value) => {
    setEditingBank((prev) => ({ ...prev, [field]: value }))
  }

  const handleEditGroupInputChange = (value) => {
    setEditingGroup((prev) => ({ ...prev, name: value }))
  }

  const columns = [
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      width: "25%",
    },
    {
      title: "БИК",
      dataIndex: "bik",
      key: "bik",
      width: "20%",
      render: (bik, record) => (record.isGroup ? null : bik),
    },
    {
      title: "Корр. счет",
      dataIndex: "coordAccount",
      key: "coordAccount",
      width: "20%",
      render: (coordAccount, record) => (record.isGroup ? null : coordAccount),
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
      width: "20%",
      render: (address, record) => (record.isGroup ? null : address),
    },
    {
      title: "Телефоны",
      dataIndex: "phones",
      key: "phones",
      width: "10%",
      render: (phones, record) => (record.isGroup ? null : phones),
    },
    {
      title: "Действия",
      key: "actions",
      width: "10%",
      render: (_, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            style={{ backgroundColor: "transparent", border: "none" }}
            onClick={() => handleEdit(record)}
          />
          <Button
            danger
            icon={<DeleteOutlined />}
            style={{ backgroundColor: "transparent", border: "none" }}
            onClick={() => handleDelete(record)}
          />
        </Space>
      ),
    },
  ]

  const components = {
    body: {
      row: DraggableRow,
    },
  }

  const handleDrop = useCallback(
    async (item, target) => {
      console.log("Dropped item:", item)
      console.log("Target :", target)

      try {
        if (item.isGroup) {
          const newParentId = target.id === null ? null : target.id

          console.log("Sending request to update group's parent")
          item.parent_id = newParentId
          const response = await axios_instance.put(
            `/counterparty/banks/group/update/${item.id}`,
            {
              parent_id: newParentId,
            }
          )
          if (response.status === 200) {
            message.success(`Группа успешно перенесена`)
            console.log(
              `Moved group ${item.id} to parent ${newParentId}`,
              response.data
            )
          } else {
            console.error(
              `Error moving group ${item.id} to parent ${newParentId}: Server returned status ${response.status}`
            )
          }
        } else if (item.bik && target.isGroup) {
          console.log("Sending request to update bank group")
          const response = await axios_instance.put(
            `/counterparty/banks/update/${item.id}`,
            {
              group_id: target.id,
            }
          )
          if (response.status === 200) {
            message.success(`Банк успешно перенесен в группу`)
            console.log(
              `Moved bank ${item.id} to group ${target.id}`,
              response.data
            )

            setGroupBanks((prev) => {
              const updated = { ...prev }

              Object.keys(updated).forEach((groupKey) => {
                if (groupKey !== target?.id?.toString()) {
                  updated[groupKey] = updated[groupKey].filter(
                    (bank) => bank.id !== item.id
                  )
                }
              })

              if (target.id && updated[target.id]) {
                updated[target.id].push({ ...item, group_id: target.id })
              } else if (target.id) {
                updated[target.id] = [{ ...item, group_id: target.id }]
              }

              return updated
            })

            item.group_id = target.id
          } else {
            console.error(
              `Error moving bank ${item.id} to group ${target.id}: Server returned status ${response.status}`
            )
          }
        } else {
          console.log("Skipping drop: invalid item or target")
          return
        }

        console.log("Refreshing data...")
        await fetchGroups()
        await fetchBanksWithoutGroup()
        if (target.id) {
          await fetchBanksByGroup(target.id)
        }
      } catch (error) {
        console.error(
          `Error moving item ${item.id} to target ${target.id}:`,
          error.response?.status,
          error.response?.data,
          error.message
        )
      }
    },
    [fetchBanksByGroup, fetchGroups, fetchBanksWithoutGroup, setGroupBanks]
  )

  const [{ isOver }, drop] = useDrop(() => ({
    accept: [ItemTypes.BANK, ItemTypes.GROUP],
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop()
      if (didDrop) {
        return
      }

      const tableRect = document
        .querySelector(".ant-table-tbody")
        ?.getBoundingClientRect()
      const clientOffset = monitor.getClientOffset()

      if (!tableRect || !clientOffset) {
        return
      }

      const isOverTable =
        clientOffset.x >= tableRect.left &&
        clientOffset.x <= tableRect.right &&
        clientOffset.y >= tableRect.top &&
        clientOffset.y <= tableRect.bottom

      if (!isOverTable) {
        console.log("Inside BanksDirectory drop - calling handleDrop")
        handleDrop(item, { id: null, isGroup: true })
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  const updateBankInTable = useCallback(
    async (updatedBank) => {
      if (searchText) {
        await fetchBanksWithSearch(searchText)
      } else {
        await fetchBanksWithoutGroup()
        if (updatedBank.group_id) {
          await fetchBanksByGroup(updatedBank.group_id)
        }
      }
    },
    [
      fetchBanksWithSearch,
      fetchBanksWithoutGroup,
      fetchBanksByGroup,
      searchText,
    ]
  )

  const handleCreateGroup = () => {
    setIsGroupModalVisible(false)
    setEditingGroup(null)
    setIsCreateGroupModalVisible(true)
  }

  const handleCreateGroupModalCancel = () => {
    setIsCreateGroupModalVisible(false)
  }

  return (
    <Spin spinning={loading}>
      <div
        ref={drop}
        style={{
          padding: "20px",
          margin: "0 auto",
          minHeight: "calc(100vh - 110px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minWidth: "1100px",
          backgroundColor: "inherit",
        }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Title level={3}>БАНКИ</Title>
        </div>
        <Space style={{ marginBottom: 16 }}>
          <Input
            placeholder="Поиск..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ width: "300px" }}
            prefix={<SearchOutlined />}
          />
          <Button
            type="primary"
            onClick={() => {
              setIsBankModalVisible(true)
              setEditingBank(null)
            }}>
            Новый банк
          </Button>
          <Button type="primary" onClick={handleCreateGroup}>
            Новая группа
          </Button>
        </Space>
        <DndProvider backend={HTML5Backend}>
          <Table
            columns={columns}
            dataSource={prepareDataForTable(
              groups,
              expandedKeys,
              searchText,
              noGroupBanks,
              groupBanks,
              banksWithSearch
            )}
            components={components}
            pagination={false}
            bordered
            style={{ flexGrow: 1 }}
            tableLayout="fixed"
            onExpand={onExpand}
            expandedRowKeys={expandedKeys}
            rowClassName={(record) =>
              record.bik ? "draggable-row" : "non-draggable-row"
            }
            expandable={{
              rowExpandable: (record) => (searchText ? false : true),
              expandIcon: ({ expanded, onExpand, record }) => {
                if (!record.bank_biscc) {
                  return (
                    <span
                      type="text"
                      onClick={(e) => onExpand(record, e)}
                      style={{
                        color: "var(--text-basic)",
                        width: "max-content",
                        cursor: "pointer",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        verticalAlign: "middle",
                        gap: "10px",
                        marginRight: "10px",
                      }}>
                      <img
                        src={chevron}
                        alt=""
                        style={{
                          width: "24px",
                          height: "24px",
                          transform: expanded
                            ? "rotate(-90deg)"
                            : "rotate(0deg)",
                        }}
                      />
                      <img
                        src={folder}
                        alt=""
                        style={{ width: "24px", height: "24px" }}
                      />
                    </span>
                  )
                } else {
                  return (
                    <span
                      type="text"
                      style={{
                        color: "var(--text-basic)",
                        width: "max-content",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        verticalAlign: "middle",
                        gap: "10px",
                        marginRight: "10px",
                      }}>
                      <div alt="" style={{ width: "24px", height: "24px" }} />
                    </span>
                  )
                }
              },
            }}
            onRow={(record, index) => {
              return {
                index,
                moveRow,
                id: record.id,
                handleDrop,
                parent_id: record.parent_id,
                ...record,
              }
            }}
          />
        </DndProvider>

        <BanksDirectiryCreateModal
          visible={isBankModalVisible}
          onCancel={handleEditBankModalCancel}
          bank={editingBank}
          updateBankInTable={updateBankInTable}
        />

        <BankGroupCreateModal
          visible={isCreateGroupModalVisible}
          onCancel={handleCreateGroupModalCancel}
          fetchGroups={fetchGroups}
        />

        <Modal
          title="Редактировать группу"
          visible={isGroupModalVisible}
          onOk={() => {
            if (editingGroup?.name) {
              handleEditGroupModalOk()
            } else {
              message.error("Пожалуйста, введите наименование группы!")
            }
          }}
          onCancel={handleEditGroupModalCancel}
          okText="Сохранить"
          cancelText="Отмена">
          <Form layout="vertical" key={editingGroup?.id}>
            <Form.Item
              label="Наименование"
              name="name"
              initialValue={editingGroup?.name}
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите наименование!",
                },
              ]}>
              <Input
                onChange={(e) => handleEditGroupInputChange(e.target.value)}
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Spin>
  )
}

export default BanksDirectory
