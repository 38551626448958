import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const DeliveryELemSkeleton = () => (
  <div className="option">
    <div className="company">
      <Skeleton circle width={40} height={40} />
      <div className="company-details">
        <Skeleton width={120} height={20} />
        <Skeleton width={80} height={15} />
      </div>
    </div>
    <div className="rating-details">
      <Skeleton width={50} height={20} />
      <Skeleton width={40} height={15} />
    </div>
    <div className="delivery-type">
      <Skeleton width={100} height={15} />
    </div>
    <div className="delivery-time">
      <Skeleton width={50} height={15} />
    </div>
    <div className="cost">
      <Skeleton width={70} height={20} />
    </div>
    <div className="select-btn-container">
      <Skeleton width={90} height={30} />
    </div>
  </div>
);

export default DeliveryELemSkeleton;
