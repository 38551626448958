import { observer } from "mobx-react-lite"; // Импортируем observer
import { Header } from "../../layout/header/Header";
import { Aside } from "../../layout/aside/Aside";
import ShippingForm from "./content/shippingForm/ShippingForm";
import { DeliveryRequest } from "./content/deliveryRequest/DeliveryRequest";
import ShippingFormStore from "../../store/ShippingFormStore";

const DeliveryPage = observer(() => {
  return (
    <>
      <Header />
      <div className="wrapper">
        <Aside
          menuItems={[
            { label: "Перевозка", path: "/shipping", key: "1" },
            { label: "Доставка", path: "/delivery", key: "2" },
            { label: "Отслеживание", path: "/tracking", key: "3" },
          ]}
        />
        {!ShippingFormStore.isSubmit && <ShippingForm />}
        {ShippingFormStore.isSubmit && <DeliveryRequest />}
      </div>
    </>
  );
});

export default DeliveryPage;
