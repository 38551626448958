import "./MainPage.css";
import { Header } from "../../layout/header/Header";
import { Aside } from "../../layout/aside/Aside";
import Content_main from "./content/Content_main";
function MainPage() {
  return (
    <>
      <Header />
      <div className="wrapper">
        <Aside
          menuItems={[
            { label: "Перевозка", path: "/shipping", key: "1" },
            { label: "Доставка", path: "/delivery", key: "2" },
            { label: "Отслеживание", path: "/tracking", key: "3" },
          ]}
        />
        <Content_main />
      </div>
    </>
  );
}

export default MainPage;
