import { makeAutoObservable } from "mobx";
import axiosInstance from "../requests/config_defaul";

class EmployeesStore {
  employees = []; // Добавлено свойство для хранения сотрудников
  isLoading = null; // Состояние загрузки
  error = null; // Ошибка при загрузке сотрудников
  success = null; // Добавить новое поле для успешного сообщения
  pre_employees = []; // Сотрудники с сервака
  current_employee = null;
  employeeForm = {
    email: "",
    tabNumber: "",
    hireDate: null,
    lastName: "",
    firstName: "",
    middleName: "",
    gender: "",
    birth_date: "",
    birthDate: null,
    organization: "",
    subdivision: "",
    position: "",
    role: "",
  };

  constructor() {
    makeAutoObservable(this);
  }

  setEmployeeField(field, value) {
    this.employeeForm[field] = value;
    console.log("Employee form value:", value, typeof value);
  }

  // Получение списка сотрудников
  async getEmployees() {
    try {
      this.isLoading = true;
      const response = await axiosInstance.get(
        "/counterparty/employees/get/all"
      );
      this.pre_employees = response.data;
      if (response.status) {
        this.employees = response.data.map((emp, index) => ({
          id: emp?.id,
          personnel_number: emp.person.person_id ?? index + 1,
          key: index + 1,
          full_name: `${emp?.person.first_name} ${emp?.person.last_name}`,
          position_name: emp?.position
            ?.map((pos) => pos?.position_name)
            .join(", "),
          role: emp?.role,
          person_password: emp?.person_password,
        }));
        console.log("Employees loaded:", this.employees);
      } else {
        this.error = "Не удалось получить сотрудников";
      }
    } catch (error) {
      this.error = "Ошибка при получении сотрудников";
      console.error("Get employees failed:", error);
    } finally {
      this.isLoading = false;
    }
  }

  async getEmployeeById(id) {
    try {
      this.isLoading = true;
      let emp = this.pre_employees.find((e) => e.id === id);
      if (!emp) {
        const response = await axiosInstance.get(
          `counterparty/employees/get/${id}`
        );
        emp = response.data;
      }
      this.current_employee = emp;
      return emp;
    } catch (error) {
      this.error = "Ошибка при получении сотрудника";
      console.error("Get employee by id failed:", error);
    } finally {
      this.isLoading = false;
    }
  }
  async createEmployee() {
    const data = {
      role: this.employeeForm.role,
      person: {
        first_name: this.employeeForm.firstName,
        last_name: this.employeeForm.lastName,
        patronymic: this.employeeForm.middleName,
        sex: this.employeeForm.gender,
        ...(this.employeeForm.birth_date && {
          birth_date: this.employeeForm.birth_date,
        }),
        person_id: this.employeeForm.tabNumber,
        contact: {
          email: this.employeeForm.email,
        },
      },
      position: {
        position_name: this.employeeForm.position,
      },
    };

    try {
      this.isLoading = true;
      const response = await axiosInstance.post(
        "/counterparty/employee/create",
        data
      );
      this.success = "Сотрудник создан."; // Устанавливаем сообщение об успехе
      try {
        const regData = {
          email: this.employeeForm.email,
          password: response.data.person_password,
          first_name: this.employeeForm.firstName,
          last_name: this.employeeForm.lastName,
          login: response.data.person_password,
        };
        // await axiosInstance.post("/registration", regData);
      } catch (error) {
        if (error.response && error.response.data.email) {
          this.error = "Email уже занят.";
        } else {
          this.error = "Ошибка при регистрации сотрудника: " + error;
        }
      }
    } catch (error) {
      this.error = "Ошибка при создании сотрудника " + error;
      return this.error;
    } finally {
      this.isLoading = false;
      await this.getEmployees(); // Обновляем список сотрудников
    }
  }

  async deleteEmployee(id) {
    try {
      this.isLoading = true;
      await axiosInstance.delete(`/counterparty/employee/delete/${id}`);
      await this.getEmployees();
      // Установить сообщение об успешном удалении
      this.success = "Сотрудник успешно удален.";
    } catch (error) {
      this.error = "Ошибка при удалении сотрудника " + error;
      return this.error;
    } finally {
      this.isLoading = false;
    }
  }

  async updateEmployee(employee_data, updatedFields) {
    try {
      this.isLoading = true;
      const data = {
        ...(updatedFields.role && {
          role: updatedFields.role === 1 ? "Администратор" : "Пользователь",
        }),
        person: {
          ...(employee_data.person.id && {
            id: employee_data.person.id,
          }),
          ...(updatedFields.firstName && {
            first_name: updatedFields.firstName,
          }),
          ...(updatedFields.lastName && { last_name: updatedFields.lastName }),
          ...(updatedFields.middleName && {
            patronymic: updatedFields.middleName,
          }),
          ...(updatedFields.tabNumber && {
            person_id: updatedFields.tabNumber,
          }),
          ...(updatedFields.gender && { sex: updatedFields.gender }),
          ...(updatedFields.birthDate && {
            birth_date: updatedFields.birthDate,
          }),
          contact: {
            ...(employee_data.person.contact.id && {
              id: employee_data.person.contact.id,
            }),
            ...(updatedFields.email && { email: updatedFields.email }),
          },
        },
      };
      console.log("Updated employee data:", data);
      const response = await axiosInstance.patch(
        `/counterparty/employee/update/${employee_data.id}`,
        data
      );
      if (response.status) {
        this.success = "Сотрудник обновлён.";
        await this.getEmployees(); // Обновляем список сотрудников
      } else {
        this.error = "Не удалось обновить сотрудника";
      }
    } catch (error) {
      this.error = "Ошибка при обновлении сотрудника " + error;
      console.error("Update employee failed:", error);
    } finally {
      this.isLoading = false;
    }
  }
}

const employeesStore = new EmployeesStore();
export default employeesStore;
