import React, { useEffect } from "react";
import "./DeliveryOptions.css";
import DeliveryELem from "../deliveryElem/DeliveryElem";
import useDeliveryFilter from "../../features/DeliveryFilter";
// import FilterPanel from "../filterPanel/FilterPanel";
import { observer } from "mobx-react-lite";
import my_ShippingFormStore from "../../store/ShippingFormStore";
import DeliveryELemSkeleton from "../deliveryElem/DeliveryELemSkeleton";

const DeliveryOptions = observer(() => {
  const { deliveryOptions, isSubmit, ResponseError } = my_ShippingFormStore;
  const {
    // options,
    sortOptions,
    // handleFilterChange,
    // resetFilters,
    // filters,
    setInitialOptions,
  } = useDeliveryFilter(deliveryOptions);

  useEffect(() => {
    setInitialOptions(deliveryOptions);
  }, [deliveryOptions]);

  const isLoading = isSubmit && deliveryOptions.length === 0; // Если запрос в процессе, показываем скелет

  useEffect(() => {
    if (isSubmit) {
      // Дополнительно можно обработать статус отправки
      console.log("Идет расчет вариантов доставки...");
    }
  }, [isSubmit]);

  return (
    <div className="delivery-options">
      {/* <FilterPanel filters={filters} handleFilterChange={handleFilterChange} /> */}
      <div className="filter-buttons">
        <button className="select-btn" onClick={() => sortOptions("cost")}>
          По стоимости
        </button>
        <button className="select-btn" onClick={() => sortOptions("time")}>
          По срокам
        </button>
        <button className="select-btn" onClick={() => sortOptions("rating")}>
          По рейтингу
        </button>
        {/* <div className="reset-filter-btn">
          <button className="select-btn" onClick={resetFilters}>
            Сбросить фильтры
          </button>
        </div> */}
      </div>
      <div className="options-header">
        <div>КОМПАНИЯ</div>
        <div>РЕЙТИНГ</div>
        <div>ДОСТАВКА</div>
        <div>ВРЕМЯ</div>
        <div>СТОИМОСТЬ</div>
      </div>

      {/* Проверка на ошибку */}
      {ResponseError ? (
        <div className="error-message">
          Произошла ошибка при загрузке данных. Пожалуйста, попробуйте позже.
        </div>
      ) : isLoading ? (
        // Если идет загрузка, показываем скелет
        Array.from({ length: 5 }).map((_, index) => (
          <DeliveryELemSkeleton key={index} />
        ))
      ) : deliveryOptions.length > 0 ? (
        // Если есть варианты доставки, показываем их
        deliveryOptions.map((option, index) => (
          <DeliveryELem key={index} option={option} />
        ))
      ) : (
        <div className="no-options">
          Нет предложений, удовлетворяющих фильтрам
        </div>
      )}
    </div>
  );
});

export default DeliveryOptions;
