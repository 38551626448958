import React from "react";
import { Header } from "../../layout/header/Header";
import { Aside } from "../../layout/asideProfile/AsideProfile";
import { Route, Routes } from "react-router-dom";
import EmployeeTable from "../../components/employeesTable/employees";
import ConterAgentsTable from "../../components/counterAgentsTable/ConterAgentsTable";
import CounterAgentRequisites from "../../components/counterAgentRequisites/CounterAgentRequisites";
import EmployeesRequisites from "../../components/employeesRequisites/EmployeesRequisites";
import BanksDirectory from "../../components/banksDirectory/BanksDirectory";
import CountriesClassifier from "../../components/сountriesClassifier/CountriesClassifier";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import WorldCountryDirectory from "../../components/worldCountryDirectory/WorldCountryDirectory";

const ProfilePage = () => {
  return (
    <>
      <Header />
      <div className="wrapper">
        <Aside />
        <Routes>
          <Route path="directory/employees" element={<EmployeeTable />} />
          <Route
            path="directory/employees/add"
            element={<EmployeesRequisites />}
          />
          <Route
            path="directory/employees/edit/:id"
            element={<EmployeesRequisites />}
          />

          <Route
            path="directory/counteragents"
            element={
              <DndProvider backend={HTML5Backend}>
                <ConterAgentsTable />
              </DndProvider>
            }
          />
          <Route
            path="directory/counteragents/add"
            element={<CounterAgentRequisites />}
          />
          <Route path="directory/banks" element={
            <DndProvider backend={HTML5Backend}>
              <BanksDirectory />
            </DndProvider>
          } />
          <Route
            path="classifier/countries"
            element={<CountriesClassifier />}
          />
          <Route
            path="directory/countries"
            element={<WorldCountryDirectory />}
          />
        </Routes>
      </div>
    </>
  );
};

export default ProfilePage;
