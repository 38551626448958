import { makeAutoObservable } from "mobx";
import axios_instance from "../requests/config_defaul";

class AuthStore {
  user = null;         // Хранение информации о пользователе
  isAuth = null;      // Статус авторизации
  isLoading = null;   // Состояние загрузки данных о пользователе
  loginError = null;
  isLogin = false;
  constructor() {
    makeAutoObservable(this);
  }

  resetError() {
    this.error = null;
  }
  // Авторизация пользователя
  async login(email, password) {
    try {
      this.isLoading = true;
      this.resetError(); // Сбрасываем ошибку перед новой попыткой
      const response = await axios_instance.post("/login", { email, password });

      if (response.data.status) {
        this.isLogin = true;
        console.log("Login successful:", response.data.status);
        await this.checkIsAuthenticated(); // Проверяем авторизацию
      } else {
        this.error = "Неверный email или пароль"; // Обрабатываем ответ с ошибкой
      }
    } catch (error) {
      this.error = "Ошибка при попытке входа"; // Устанавливаем ошибку
      console.error("Login failed:", error);
    } finally {
      this.isLoading = false; // Завершаем загрузку
    }
  }

  // Проверка, авторизован ли пользователь
  async checkIsAuthenticated() {
    try {
      this.isLoading = true; // Устанавливаем загрузку
      const response = await axios_instance.get('/is_authenticated');
      if (response.data.status) {
        this.isAuth = true;
        await this.getMe();
      } else {
        this.isAuth = false;
        this.user = null;
      }
    } catch (error) {
      console.error("Not authenticated", error);
    } finally {
      this.isLoading = false; // Завершаем загрузку
    }
  }

  // Выход пользователя
  async logout() {
    try {
      const response = await axios_instance.get("/logout");
      console.log("Logout successful:", response.data);
      this.isAuth = false;
      this.user = null;  // Очистка данных о пользователе
      this.isLogin = false;
    } catch (error) {
      console.error("Logout failed:", error);
    } 
  }

  // Получение информации о пользователе
  async getMe() {
    try {
      this.isLoading = true; // Устанавливаем загрузку
      const response = await axios_instance.get("/getMe");
      console.log("Get me successful:", response.data);
      this.user = response.data.user;  // Предполагаем, что ответ содержит информацию о пользователе
    } catch (error) {
      console.error("Get me failed:", error);
      this.user = null;  // Если не удалось получить данные, очищаем информацию о пользователе
    } finally {
      this.isLoading = false; // Завершаем загрузку
    }
  }
}

const authStore = new AuthStore();
export default authStore;