import React from "react";
import { Modal, Input, Button, Form, Typography, Alert } from "antd";
import { observer } from "mobx-react-lite";
import authStore from "../../store/AuthStore";
import { useNavigate } from "react-router-dom";
const { Link } = Typography;

const LoginModal = observer(({ visible, onCancel }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    await authStore.login(values.email, values.password);
    if (authStore.isAuth) {
      navigate("/profile");
      onCancel();
    }
  };

  return (
    <Modal
      open={visible}
      title="Вход в систему"
      onCancel={onCancel}
      footer={null}
      width={470}
      height={320}
      transitionName=""
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        {/* Отображение ошибки */}
        {authStore.error && (
          <Alert
            message={authStore.error}
            type="error"
            showIcon
            style={{ marginBottom: 16 }}
          />
        )}

        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: "Пожалуйста, введите email!" }]}
        >
          <Input placeholder="Введите email" />
        </Form.Item>

        <Form.Item
          label="Пароль"
          name="password"
          rules={[{ required: true, message: "Пожалуйста, введите пароль!" }]}
        >
          <Input.Password placeholder="Введите пароль" />
        </Form.Item>

        <Form.Item>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Link href="#">Забыли пароль?</Link>
          </div>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            block
            style={{ backgroundColor: "#E4E4E4" }}
            loading={authStore.isLoading} // Отображение состояния загрузки
          >
            Войти
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default LoginModal;
