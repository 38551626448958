export const validateShippingForm = (formData) => {
    const {

      departureAddress,

      destinationAddress,
      cargoType,
      cargoWeight,
      cargoLength,
      cargoWidth,
      cargoHeight,
      cargoVolume,
      deliveryType,
    } = formData;
  
    if (
  
      !departureAddress ||

      !destinationAddress ||
      !cargoType ||
      !cargoWeight ||
      (cargoType === "Груз" &&
        (!cargoLength || !cargoWidth || !cargoHeight || !cargoVolume)) ||
      !deliveryType
    ) {
      return false; 
    }
  
    return true;
  };
  