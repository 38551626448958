import { Navigate } from "react-router-dom";
import authStore from "../store/AuthStore";
import { observer } from "mobx-react-lite";
import { message } from "antd"; // Добавлен импорт message
import { useEffect } from "react"; // Добавлен импорт useEffect

const ProtectedRoute = observer(({ children }) => {
  useEffect(() => {
    if (!authStore.isAuth) {
      message.error("Недостаточно прав доступа");
    }
  }, [authStore.isAuth]);

  if (!authStore.isAuth) {
    return <Navigate to="/" />;
  }

  return children;
});

export default ProtectedRoute;
