import React, { useEffect } from "react";
import { observer } from "mobx-react-lite"; // Добавлен импорт observer
import { Table, Button, Typography, Space, Alert, message } from "antd"; // Добавить импорт Alert
import { useNavigate } from "react-router-dom";
import styles from "./EmployeeTable.module.css";
import employeeStore from "../../store/EmployeesStore"; // Импорт магазина
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

const { Title } = Typography;

const EmployeeTable = () => {
  const navigate = useNavigate();

  useEffect(() => {
    employeeStore.getEmployees();
  }, []);

  const columns = [
    {
      title: "Табельный №",
      dataIndex: "personnel_number",
      key: "personnel_number",
    },
    {
      title: "ФИО",
      dataIndex: "full_name",
      key: "full_name",
      sorter: (a, b) => a.full_name.localeCompare(b.full_name),
      sorterTooltip: false,
    },
    {
      title: "Должность",
      dataIndex: "position_name",
      key: "position_name",
    },
    {
      title: "Роль",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Пароль",
      dataIndex: "person_password",
      key: "person_password",
    },
    {
      title: "Действия",
      key: "actions",
      width: 120,
      render: (_, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            style={{ backgroundColor: "transparent", border: "none" }}
            onClick={() => handelEdit(record.id)}
          />
          <Button
            danger
            icon={<DeleteOutlined />}
            style={{ backgroundColor: "transparent", border: "none" }}
            onClick={() => handleDelete(record.id)}
          />
        </Space>
      ),
    },
  ];

  const handelEdit = (id) => {
    navigate(`edit/${id}`);
  };

  const handleDelete = async (id) => {
    await employeeStore.deleteEmployee(id);
    if (employeeStore.error) {
      message.error(employeeStore.error);
    } else {
      message.success(employeeStore.success);
    }
  };

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.titleWrapper}>
          <Title level={3}>ШТАТНЫЕ СОТРУДНИКИ</Title>
        </div>
        <Button
          type="primary"
          className={styles.newEmployeeButton}
          onClick={() => navigate("add")}
        >
          Новый сотрудник
        </Button>
        <Table
          columns={columns}
          dataSource={employeeStore.employees}
          loading={employeeStore.isLoading}
          showSorterTooltip={false}
          bordered
          pagination={{ pageSize: 5 }}
          className={styles.table}
          locale={{
            emptyText: employeeStore.error ? (
              <Alert
                message="Ошибка"
                description={employeeStore.error}
                type="error"
              />
            ) : (
              <Alert
                message="Нет данных"
                description="Нет данных для отображения"
                type="info"
              />
            ),
          }}
        />
      </div>
      <div className={styles.footerButtonWrapper}>
        <Button type="primary" className={styles.footerButton}>
          Посмотреть
        </Button>
      </div>
    </div>
  );
};

export default observer(EmployeeTable); // Обертывание компонента в observer
