import React, { useState } from "react";
import { Table, Input, Button, Space, Tag } from "antd";
import {
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { filteredData, validateData } from "../../features/DataSearcherUtils";
import CounterAgentAdressesTabContentModal from "../сounterAgentAdressesTabContentModal/CounterAgentAdressesTabContentModal";
const CounterAgentAdressesTabContent = () => {
  const [searchText, setSearchText] = useState("");
  const [visible, setVisible] = useState(false);
  const statusColors = {
    активный: "green",
    устаревший: "red",
    новый: "yellow",
    заблокирован: "volcano",
  };
  const data = [
    {
      key: "1",
      name: "Офис фактический",
      address: "Совпадает с юридическим",
      status: "активный",
    },
    {
      key: "2",
      name: "Офис юридический",
      address: "125475, Москва г, Зеленоградская ул, дом № 18А, комната 15",
      status: "устаревший",
    },
    {
      key: "3",
      name: "Терминал Шереметьево",
      address: "Южный терминальный комплекс, рядом с терминалами D, E, F",
      status: "не проверен",
    },
    {
      key: "4",
      name: "Терминал Домодедово",
      address: "Терминал «Аэроэкспресс» в а/п Домодедово",
      status: "активный",
    },
  ];
  const columns = [
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      width: "20%", // Установлена ширина 20%
      sorter: (a, b) => a.name.localeCompare(b.name), // добавлена сортировка по наименованию
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
      width: "70%", // Установлена ширина 70%
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (status) =>
        status ? (
          <Tag color={statusColors[status]}>{status.toUpperCase()}</Tag>
        ) : (
          <></>
        ),
    },
    {
      title: "Действия",
      key: "actions",
      width: "10%", // Установлена ширина 10%
      render: (_, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            style={{ backgroundColor: "transparent", border: "none" }}
          />
          <Button
            danger
            icon={<DeleteOutlined />}
            style={{ backgroundColor: "transparent", border: "none" }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div
      style={{
        padding: "20px",
        margin: "0 auto",
        minHeight: "calc(100vh - 110px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Space style={{ marginBottom: 16 }}>
        <Input
          placeholder="Поиск по названию"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: "300px" }}
          prefix={<SearchOutlined />}
        />
        <Button type="primary" onClick={() => setVisible(true)}>
          Новый адрес
        </Button>
        <Button type="primary">Новая группа</Button>
      </Space>

      <Table
        columns={columns}
        dataSource={filteredData(validateData(data), searchText)}
        pagination
        bordered
        style={{ flexGrow: 1 }}
      />
      {
        <CounterAgentAdressesTabContentModal
          visible={visible}
          setVisible={setVisible}
        />
      }
    </div>
  );
};

export default CounterAgentAdressesTabContent;
