export const filteredData = (items = [], searchText = '') => {
  return items
    .filter(
      (item) =>
        (item?.name || '').toLowerCase().includes(searchText.toLowerCase()) ||
        (item.children && filteredData(item.children, searchText).length > 0)
    )
    .map((item) => ({
      ...item,
      children: item.children ? filteredData(item.children, searchText) : null,
    }));
};

export const validateData = (items) => {
  return items.map((item) => ({
    ...item,
    status: item.status || null,
    children: item.children ? validateData(item.children) : null,
  }));
};