import React, { useEffect, useCallback } from "react";
import { Button, Form, Input, Select, Modal, message } from "antd";
import axios_instance from "../../requests/config_defaul";

const BanksDirectiryCreateModal = ({
  visible,
  onCancel,
  bank,
  updateBankInTable,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    console.log("Bank object:", bank);
    if (visible) {
      if (bank) {
        const formValues = {
          BIK: bank.bank_biscc,
          name: bank.bank_name,
          coordAccount: bank.corr_account,
          address: bank.address,
          phones: bank.phone_number,
          city: bank.city_id,
          country: bank.country_id,
        };
        console.log("Setting form values:", formValues);
        form.setFieldsValue(formValues);
        console.log("Current form values (after set):", form.getFieldsValue());
      } else {
        form.resetFields();
      }
    } else {
      form.resetFields();
    }
  }, [bank, form, visible]);

  const onUpdateSuccess = useCallback((updatedBank) => {
    if (typeof updateBankInTable === "function") {
      updateBankInTable(updatedBank);
    }
  }, [updateBankInTable]);

  const handleSubmit = useCallback(async (values) => {
      try {
          const data = {
              corr_account: values.coordAccount,
              bank_name: values.name,
              phone_number: values.phones,
              bank_inn: "",
              bank_biscc: values.BIK,
              city: values.city,
              address: values.address,
              bank_name_eng: "",
              city_eng: "",
              address_eng: "",
          };

          let response;

          if (bank) {
              data.id = bank.id;
              response = await axios_instance.put(
                  `/counterparty/banks/update/${bank.id}`,
                  data
              );
              message.success({
                  content: "Банк успешно обновлен!",
              });
              onUpdateSuccess({ ...bank, ...data });
          } else {
              response = await axios_instance.post(
                  "/counterparty/banks/create",
                  data              );
                  message.success({
                      content: "Банк успешно создан!",
                  });
              }
    
              if (response.status === 200) {
                  form.resetFields();
                  onCancel();
              } else {
                  console.error(
                      "Failed to create/update bank:",
                      response.status,
                      response.data
                  );
                  message.error({
                      content: response.data.detail || "Не удалось создать/обновить банк.",
                  });
              }
          } catch (error) {
              console.error("Error creating/updating bank:", error);
              message.error({
                  content: "Не удалось создать/обновить банк.",
              });
          }
      }, [bank, form, onCancel, onUpdateSuccess]);
    
      return (
        <Modal
          open={visible}
          title={<div style={{ textAlign: "center", color: "black" }}>Банк</div>}
          onCancel={onCancel}
          footer={null}
          width={560}
          height={500}
          transitionName=""
        >
          <Form
            form={form}
            layout={"vertical"}
            requiredMark={false}
            onFinish={handleSubmit}
          >
            <Form.Item label="BIK">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Form.Item
                  name="BIK"
                  noStyle 
                  rules={[
                    {
                      required: true,
                      message: "Пожалуйста, введите БИК!",
                    },
                    {
                      pattern: /^\d{9}$/,
                      message: "БИК должен содержать 9 цифр",
                    },
                  ]}
                >
                  <Input placeholder="Введите БИК банка" style={{ flex: 1 }} />
                </Form.Item>
                <Button type="default" style={{ marginLeft: 8 }} onClick={() => {message.info("Будет добавлено позже")}}>
                  Заполнить по БИК
                </Button>
              </div>
            </Form.Item>
    
            <Form.Item
              label="Наименование"
              name="name"
              rules={[
                { required: true, message: "Пожалуйста, введите наименовие!" },
              ]}
            >
              <Input placeholder="Введите наименование банка" />
            </Form.Item>
    
            <Form.Item
              label="Корр. счет"
              name="coordAccount"
              rules={[
                { required: true, message: "Пожалуйста, введите корр. счет!" },
                {
                  pattern: /^\d{20}$/,
                  message: "Корр. счет должен содержать 20 цифр",
                },
              ]}
            >
              <Input placeholder="Введите корреспондентский счет" />
            </Form.Item>
    
            <Form.Item
              label="Страна"
              name="country"
              rules={[{ required: true, message: "Пожалуйста, выберите страну!" }]}
            >
              <Select
                placeholder="Выберите страну"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
              >
                <Select.Option
                  value="e78453d5-c1b6-4e4d-b1f8-51f75751b07d"
                  label="Россия"
                >
                  Россия
                </Select.Option>
              </Select>
            </Form.Item>
    
            <Form.Item
              label="Город"
              name="city"
              rules={[{ required: true, message: "Пожалуйста, выберите город!" }]}
            >
              <Select
                placeholder="Выберите город"
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                }
              >
                <Select.Option
                  value="b9a99345-5468-4699-b57e-3f15759c44f7"
                  label="Казань"
                >
                  Казань
                </Select.Option>
              </Select>
            </Form.Item>
    
            <Form.Item
              label="Адрес"
              name="address"
              rules={[{ required: true, message: "Пожалуйста, введите адрес!" }]}
            >
              <Input placeholder="Введите адрес" />
            </Form.Item>
    
            <Form.Item
              label="Телефон"
              name="phones"
              rules={[
                {
                  required: false,
                  message: "Пожалуйста, введите телефоны!",
                },
              ]}
            >
              <Input placeholder="Введите контактные телефоны" />
            </Form.Item>
    
            <Form.Item style={{ display: "flex", justifyContent: "center" }}>
              <Button type="primary" htmlType="submit">
                Сохранить
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      );
    };
    
    export default BanksDirectiryCreateModal;