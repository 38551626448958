import { observer } from "mobx-react-lite";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Row,
  Col,
  message,
} from "antd";
import React, { useEffect } from "react";
import employeesStore from "../../store/EmployeesStore";
import moment from "moment";
import { useParams } from "react-router-dom";

const { Option } = Select;

// Опции для Select
const organizationOptions = [
  { value: "1", label: 'ООО "Рога и копыта"' },
  { value: "2", label: 'ООО "Колесо"' },
  { value: "3", label: 'ООО "Колесо"' },
];

const subdivisionOptions = [
  { value: "1", label: "Отдел продаж" },
  { value: "2", label: "Отдел закупок" },
  { value: "3", label: "Отдел кадров" },
];

const positionOptions = [
  { value: "1", label: "Менеджер" },
  { value: "2", label: "Директор" },
  { value: "3", label: "Бухгалтер" },
];

const roleOptions = [
  { value: "1", label: "Администратор" },
  { value: "2", label: "Пользователь" },
];

const EmployeesDetailForm = observer(() => {
  const [form] = Form.useForm();

  //TODO: Set Date
  const { id } = useParams();
  useEffect(() => {
    const fetchEmployee = async () => {
      if (id) {
        console.log("Edit mode");
        await employeesStore.getEmployeeById(id);
        const my_emp = employeesStore.current_employee;
        if (my_emp && my_emp.person) {
          form.setFieldsValue({
            email: my_emp?.person?.contact?.email ?? "",
            tabNumber: my_emp?.person?.person_id ?? "",
            hireDate: my_emp?.hire_date ? moment(my_emp.hire_date) : null,
            lastName: my_emp?.person?.last_name ?? "",
            firstName: my_emp?.person?.first_name ?? "",
            middleName: my_emp?.person?.patronymic ?? "",
            gender: my_emp?.person?.sex ?? "",
            birthDate: my_emp.person.birth_date
              ? moment(my_emp.person.birth_date.trim(), "YYYY-MM-DD")
              : null,
            organization: my_emp?.organization ?? "",
            subdivision: my_emp?.subdivision ?? "",
            position: Array.isArray(my_emp?.position)
              ? my_emp.position.map((pos) => pos.position_name).join(", ")
              : "",
            role: my_emp?.role ?? "",
          });
        } else {
          console.error("Employee data is incomplete or missing");
        }
      }
    };
    fetchEmployee();
  }, [id, form]);

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      if (id) {
        console.log("Changed fields:", values);
        await employeesStore.updateEmployee(
          employeesStore.current_employee,
          values
        );
      } else {
        // Режим создания
        await employeesStore.createEmployee();
      }
      if (employeesStore.error) {
        message.error(employeesStore.error);
      } else {
        message.success(
          id ? "Сотрудник успешно обновлён!" : "Сотрудник успешно добавлен!"
        );
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
      message.error("Пожалуйста, заполните все обязательные поля.");
    }
  };

  return (
    <Form layout="vertical" form={form}>
      <Row gutter={20}>
        <Col span={12}>
          <Form.Item
            name="email" // Удалено свойство rules
            label="Email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Пожалуйста, введите корректный email!",
              },
            ]}
          >
            <Input
              placeholder="Введите email"
              style={{ gridColumn: "span 2" }}
              onChange={(e) =>
                employeesStore.setEmployeeField("email", e.target.value)
              }
              value={employeesStore.employeeForm.email}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="tabNumber" // Удалено свойство rules
            label="Табельный номер"
          >
            <Input
              placeholder="Введите табельный номер"
              onChange={(e) =>
                employeesStore.setEmployeeField("tabNumber", e.target.value)
              }
              value={employeesStore.employeeForm.tabNumber}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="employmentDate" // Удалено свойство rules
            label="Дата приема на работу"
          >
            <DatePicker
              style={{ width: "100%" }}
              onChange={(date) =>
                employeesStore.setEmployeeField(
                  "employmentDate",
                  date ? date.format("YYYY-MM-DD") : null
                )
              }
              value={
                employeesStore.employeeForm.employmentDate
                  ? moment(employeesStore.employeeForm.employmentDate)
                  : null
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={6}>
          <Form.Item
            name="lastName" // Оставлено свойство rules
            label="Фамилия"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите фамилию!",
              },
            ]}
          >
            <Input
              placeholder="Введите фамилию сотрудника"
              onChange={(e) =>
                employeesStore.setEmployeeField("lastName", e.target.value)
              }
              value={employeesStore.employeeForm.lastName}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="firstName" // Оставлено свойство rules
            label="Имя"
            rules={[
              {
                required: true,
                message: "Пожалуйста, введите имя!",
              },
            ]}
          >
            <Input
              placeholder="Введите имя сотрудника"
              onChange={(e) =>
                employeesStore.setEmployeeField("firstName", e.target.value)
              }
              value={employeesStore.employeeForm.firstName}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="middleName" // Удалено свойство rules
            label="Отчество"
          >
            <Input
              placeholder="Введите отчество сотрудника"
              onChange={(e) =>
                employeesStore.setEmployeeField("middleName", e.target.value)
              }
              value={employeesStore.employeeForm.middleName}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="gender" // Удалено свойство rules
            label="Пол"
          >
            <Select
              placeholder="Выберите пол"
              onChange={(value) =>
                employeesStore.setEmployeeField("gender", value)
              }
              value={employeesStore.employeeForm.gender}
            >
              <Option value="Муж.">Муж.</Option>
              <Option value="Жен.">Жен.</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={6}>
          <Form.Item
            name="birthDate" // Удалено свойство rules
            label="Дата рождения"
          >
            <DatePicker
              style={{ width: "100%" }}
              onChange={(date) =>
                employeesStore.setEmployeeField(
                  "birth_date",
                  date ? date.format("YYYY-MM-DD") : null
                )
              }
              value={
                employeesStore.employeeForm.birth_date
                  ? moment(employeesStore.employeeForm.birth_date)
                  : null
              }
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={8}>
          <Form.Item
            name="organization" // Удалено свойство rules
            label="Организация"
          >
            <Select
              placeholder="Выберите организацию"
              onChange={(value) =>
                employeesStore.setEmployeeField("organization", value)
              }
              value={employeesStore.employeeForm.organization}
            >
              {organizationOptions.map((option) => (
                <Option key={option.value} value={option.label}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="subdivision" // Удалено свойство rules
            label="Подразделение"
          >
            <Select
              placeholder="Выберите подразделение"
              onChange={(value) =>
                employeesStore.setEmployeeField("subdivision", value)
              }
              value={employeesStore.employeeForm.subdivision}
            >
              {subdivisionOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            name="position" // Удалено свойство rules
            label="Должность"
          >
            <Select
              placeholder="Выберите должность"
              onChange={(value) => {
                const positionOptions = positionOptions.find(
                  (option) => option.value === value
                );
                if (positionOptions) {
                  employeesStore.setEmployeeField(
                    "organization",
                    positionOptions.label
                  );
                }
              }}
              value={employeesStore.employeeForm.position}
            >
              {positionOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20}>
        <Col span={8}>
          <Form.Item
            name="role" // Оставлено свойство rules
            label="Роль в системе"
            rules={[
              {
                required: true,
                message: "Пожалуйста, выберете роль в системе!",
              },
            ]}
          >
            <Select
              placeholder="Выберите роль в системе"
              onChange={(value) => {
                const selectedRoleOption = roleOptions.find(
                  (option) => option.value === value
                );
                if (selectedRoleOption) {
                  employeesStore.setEmployeeField(
                    "role",
                    selectedRoleOption.label
                  );
                }
              }}
              value={employeesStore.employeeForm.role}
            >
              {roleOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <Col>
          <Button type="primary" onClick={handleSubmit}>
            Сохранить
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

export default EmployeesDetailForm;
