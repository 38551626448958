import { makeAutoObservable, reaction } from "mobx";
import cse_logo from "../public/cse_logo.svg";
import { DeliveryRequestBase } from "../features/DeliveryRequstBase";

class ShippingFormStore {
  departureAddress = "";
  departureAddressName = "";
  destinationAddress = "";
  destinationAddressName = "";
  cargoType = "";
  cargoWeight = "";
  cargoLength = "";
  cargoWidth = "";
  cargoHeight = "";
  cargoVolume = "";
  deliveryType = "";
  errors = {};
  isSubmit = false;
  deliveryOptions = []; 
  ResponseError = false; 

  constructor() {
    makeAutoObservable(this);


    reaction(
      () => this.isSubmit,
      (isSubmit) => {
        if (isSubmit) {
          this.sendCalculationRequest(); 
        }
      }
    );
  }


  setField(field, value) {
    this[field] = value;
    this.validateField(field);


    if (["cargoLength", "cargoWidth", "cargoHeight"].includes(field)) {
      this.calculateVolume();
    }
  }


  validateField(field) {
    let value = this[field];
    if (value === "") {
      delete this.errors[field];
      return;
    }
    if (field === "cargoWeight" && (isNaN(value) || value <= 0)) {
      this.errors[field] = "Вес должен быть положительным числом";
    } else if (
      ["cargoLength", "cargoWidth", "cargoHeight"].includes(field) &&
      (isNaN(value) || value <= 0)
    ) {
      this.errors[field] = "Габариты должны быть положительными числами";
    } else {
      delete this.errors[field];
    }

}

  calculateVolume() {
    if (
      this.cargoLength > 0 &&
      this.cargoWidth > 0 &&
      this.cargoHeight > 0
    ) {
      this.cargoVolume = (
        this.cargoLength *
        this.cargoWidth *
        this.cargoHeight
      ).toFixed(2);
    } else {
      this.cargoVolume = "";
    }
  }


  resetForm() {
    this.departureAddress = "";
    this.destinationAddress = "";
    this.cargoType = "";
    this.cargoWeight = "";
    this.cargoLength = "";
    this.cargoWidth = "";
    this.cargoHeight = "";
    this.cargoVolume = "";
    this.deliveryType = "";
    this.errors = {};
    this.isSubmit = false;
    this.deliveryOptions = [];
    this.ResponseError = false;
  }


  setSubmitStatus(status) {
    this.isSubmit = status;
  }


  async sendCalculationRequest() {

    this.deliveryOptions = [];
    this.ResponseError = false;


    const isDocument = this.cargoType.toLowerCase() === "документы"; 
    const orderItem = {
      weight: parseFloat(this.cargoWeight), 
      sender_address: this.departureAddress ,
      count: 1,
    };
    console.log(typeof orderItem.sender_address, orderItem.sender_address);

    if (!isDocument) {
      Object.assign(orderItem, {
        length: parseFloat(this.cargoLength),
        width: parseFloat(this.cargoWidth),
        height: parseFloat(this.cargoHeight),
      });
    }

    const payload = {
      directions: [
        {
          endpoint: this.destinationAddress, 
          order_items: [orderItem],
        },
      ],
      aggregator: {
        id: "fda1603a-ea41-080b-9322-808c08de402b", 
        port: 10101,
        accessor_key: "cse",
      },
    };

    try {
      const result = await DeliveryRequestBase(payload); 


      this.deliveryOptions = result.map((option) => ({
        companyName: "КСЭ", 
        serviceType: option.urgency_name, 
        rating: "4.3/5", 
        reviews: "14,356 отзывов", 
        deliveryType: "Склад-Склад", 
        deliveryTime: `${option.min_period} - ${option.max_period} рабочих дня`, 
        cost: `${option.delivery_cost} ₽`, 
        logo: cse_logo, 
      }));
      this.ResponseError = false;
    } catch (error) {
      this.ResponseError = true;
      console.error("Ошибка при получении вариантов доставки:", error);
    }
  }


  get formData() {
    return {

      departureAddress: this.departureAddress,
      destinationAddress: this.destinationAddress,
      cargoType: this.cargoType,
      cargoWeight: this.cargoWeight,
      cargoLength: this.cargoLength,
      cargoWidth: this.cargoWidth,
      cargoHeight: this.cargoHeight,
      cargoVolume: this.cargoVolume,
      deliveryType: this.deliveryType,
    };
  }


  hasErrors() {
    return Object.keys(this.errors).length > 0;
  }
}

const my_ShippingFormStore = new ShippingFormStore();
export default my_ShippingFormStore;
