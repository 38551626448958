import React, { useState, useEffect, useCallback, useRef } from "react"
import {
  Table,
  Input,
  Button,
  Tag,
  Space,
  Typography,
  Spin,
  Modal,
  Select,
  Form,
  message,
} from "antd"
import { SearchOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons"
import { useNavigate } from "react-router-dom"
import { useDrag, useDrop } from "react-dnd"
import CounterAgentGroupCreateModal from "../counterAgentGroupCreateModal/CounterAgentGroupCreateModal"
import { filteredData, validateData } from "../../features/DataSearcherUtils"
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import axios_instance from "../../requests/config_defaul"
import folder from "../../public/icn_folder.svg"
import chevron from "../../public/icn_chevron.svg"
import { ItemTypes } from "./ItemTypes"

const statusColors = {
  активный: "green",
  неактивный: "red",
  новый: "yellow",
  заблокирован: "volcano",
}

const { Title } = Typography
const { Option } = Select

const DraggableRow = ({ index, moveRow, children, ...restProps }) => {
  const ref = useRef(null)
  const [itemState, setItemState] = useState({ ...restProps })

  useEffect(() => {
    setItemState((prevItemState) => {
      if (prevItemState.parent_id !== restProps.parent_id) {
        return { ...restProps }
      }
      return prevItemState
    })
  }, [restProps.parent_id])

  const [{ isDragging }, drag, preview] = useDrag(() => ({
    type: ItemTypes.COUNTER_AGENT,
    item: () => ({ index, ...itemState, isAgent: !itemState.isGroup }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => !itemState.isGroup,
  }))

  const [{ isDraggingGroup }, groupDrag] = useDrag(() => ({
    type: ItemTypes.GROUP,
    item: () => ({ index, ...itemState, isGroup: true }),
    collect: (monitor) => ({
      isDraggingGroup: monitor.isDragging(),
    }),
    canDrag: () => itemState.isGroup,
  }))

  const [, drop] = useDrop(() => ({
    accept: [ItemTypes.COUNTER_AGENT, ItemTypes.GROUP],
    hover: (item, monitor) => {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const monitorItem = monitor.getItem()

      if (
        itemState.isGroup &&
        monitorItem.group_id !== itemState.id &&
        monitor.isOver({ shallow: true })
      ) {
        return
      }

      if (typeof dragIndex !== "number" || typeof hoverIndex !== "number")
        return

      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      if (typeof item.moveRow === "function") {
        item.moveRow(dragIndex, hoverIndex)
      }
      item.index = hoverIndex
    },
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop()
      const targetGroup = itemState

      if (didDrop || !targetGroup.isGroup) {
        return
      }
      restProps.handleDrop(item, targetGroup)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      isOverCurrent: monitor.isOver({ shallow: true }),
    }),
  }))

  const [, groupDrop] = useDrop(() => ({
    accept: ItemTypes.GROUP,
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop()
      const targetGroup = itemState

      if (didDrop || !targetGroup.isGroup) {
        return
      }
      restProps.handleDrop(item, targetGroup)
    },
    collect: (monitor) => ({
      isOverGroup: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  }))

  const combinedRef = (node) => {
    ref.current = node
    drag(node)
    groupDrag(node)
    drop(node)
    groupDrop(node)
  }

  return (
    <tr
      ref={combinedRef}
      key={itemState.id}
      style={{
        cursor: "move",
        opacity: isDragging || isDraggingGroup ? 0.5 : 1,
        backgroundColor:
          groupDrop[0]?.isOverGroup && groupDrop[0].canDrop
            ? "lightgreen"
            : "inherit",
      }}
      {...restProps}>
      {children}
    </tr>
  )
}

const ConterAgentsTable = () => {
  const [searchText, setSearchText] = useState("")
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [editingCounterAgent, setEditingCounterAgent] = useState(null)
  const [isEditGroupModalVisible, setIsEditGroupModalVisible] = useState(false)
  const [editingGroup, setEditingGroup] = useState(null)
  const [groups, setGroups] = useState([])
  const [loading, setLoading] = useState(false)
  const [expandedKeys, setExpandedKeys] = useState([])
  const [noGroupCounterAgents, setNoGroupCounterAgents] = useState([])
  const [groupCounterAgents, setGroupCounterAgents] = useState({})
  const [counterAgentsWithSearch, setCounterAgentsWithSearch] = useState([])
  const navigate = useNavigate()

  const fetchCounterAgentsWithoutGroup = useCallback(async () => {
    setLoading(true)
    try {
      const response = await axios_instance.get("/counterparty/get/all", {
        params: { group_id: "" },
      })
      setNoGroupCounterAgents(response.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [])

  const fetchCounterAgentsWithSearch = useCallback(async (searchQuery) => {
    setLoading(true)
    try {
      const params = { search: searchQuery }
      if (!searchQuery) {
        return
      }
      const response = await axios_instance.get("/counterparty/get/all", {
        params,
      })

      const filteredAgents = response.data.filter((agent) => {
        return (
          agent.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          (agent.inn &&
            agent.inn.toLowerCase().includes(searchQuery.toLowerCase())) ||
          (agent.full_name &&
            agent.full_name
              .toLowerCase()
              .includes(searchQuery.toLowerCase())) ||
          (agent.tag &&
            agent.tag.toLowerCase().includes(searchQuery.toLowerCase()))
        )
      })
      setGroupCounterAgents({})
      setCounterAgentsWithSearch(filteredAgents)
      setExpandedKeys([])
      setNoGroupCounterAgents([])
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [])

  const fetchCounterAgentsByGroup = useCallback(async (groupId) => {
    setLoading(true)
    try {
      const response = await axios_instance.get("/counterparty/get/all", {
        params: { group_id: groupId },
      })
     

      setGroupCounterAgents((prevGroupCounterAgents) => ({
        ...prevGroupCounterAgents,
        [groupId]: response.data,
      }))
    } catch (error) {
     
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (searchText) {
        fetchCounterAgentsWithSearch(searchText)
      } else {
        setCounterAgentsWithSearch([])
        fetchCounterAgentsWithoutGroup()
        fetchGroups()
      }
    }, 500)

    return () => clearTimeout(timeOutId)
  }, [searchText, fetchCounterAgentsWithSearch, fetchCounterAgentsWithoutGroup])

  const fetchGroups = useCallback(async () => {
    setLoading(true)
    try {
      const response = await axios_instance.get("/counterparty/group/all")
      setGroups(response.data)
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }, [])

  const mapStatusIdToStatus = (statusId) => {
    if (statusId === null) return "новый"
    return "активный"
  }

  const findGroup = (groupId, groups) => {
    for (const group of groups) {
      if (group.id === groupId) {
        return group
      }
      if (group.children) {
        const found = findGroup(groupId, group.children)
        if (found) return found
      }
    }
    return null
  }

  const onExpand = (expanded, record) => {
    const key = record.key
    if (expanded) {
      if (!groupCounterAgents[key]) {
        fetchCounterAgentsByGroup(key)
      }
      setExpandedKeys((prevExpandedKeys) => [...prevExpandedKeys, key])
    } else {
      setExpandedKeys((prevExpandedKeys) =>
        prevExpandedKeys.filter((k) => k !== key)
      )
    }
  }

  const prepareDataForTable = (
    groups,
    expandedKeys,
    searchText,
    noGroupCounterAgents,
    groupCounterAgents,
    counterAgentsWithSearch
  ) => {
    const data = []

    const mapGroup = (group) => {
      const mappedGroup = {
        key: group.id,
        name: group.group_name,
        children: [],
        isGroup: true,
        id: group.id,
        parent_id: group.parent_id,
      }

      if (!searchText) {
        if (
          expandedKeys.includes(group.id) ||
          isDescendantOfExpanded(group, expandedKeys, groups)
        ) {
          const agents = groupCounterAgents[group.id] || []
          agents.forEach((agent) => {
            mappedGroup.children.push(mapAgent(agent))
          })
        }

        if (group.children) {
          group.children.forEach((childGroup) => {
            mappedGroup.children.push(mapGroup(childGroup))
          })
        }
      }

      return mappedGroup
    }

    const mapAgent = (agent) => ({
      key: `agent-${agent.id}`,
      id: agent.id,
      name: agent.name,
      inn: agent.inn,
      fullName: agent.full_name,
      tag: agent.tag,
      status: mapStatusIdToStatus(agent.status_id),
      group_id: agent?.group_id,
      isAgent: true,
      ...agent,
    })

    const isDescendantOfExpanded = (group, expandedKeys, allGroups) => {
      if (!group.parent_id) return false
      if (expandedKeys.includes(group.parent_id)) return true
      const parentGroup = findGroup(group.parent_id, allGroups)
      return parentGroup
        ? isDescendantOfExpanded(parentGroup, expandedKeys, allGroups)
        : false
    }

    if (!searchText) {
      groups
        .filter((group) => group.parent_id === null)
        .forEach((group) => {
          data.push(mapGroup(group))
        })

      noGroupCounterAgents.forEach((agent) => {
        data.push(mapAgent(agent))
      })
    } else {
      counterAgentsWithSearch.forEach((agent) => {
        data.push(mapAgent(agent))
      })
    }

    const sortData = (items) => {
      return items.map((item) => ({
        ...item,
        children: item.children
          ? sortData(item.children).sort((a, b) => {
              if (a.name && b.name) {
                return a.name.localeCompare(b.name)
              } else if (a.name) {
                return -1
              } else if (b.name) {
                return 1
              } else {
                return 0
              }
            })
          : [],
      }))
    }

    return sortData(data)
  }

  const moveRow = useCallback((dragIndex, hoverIndex) => {}, [])

  useEffect(() => {
    if (!searchText) {
      fetchGroups()
      fetchCounterAgentsWithoutGroup()
    }
  }, [fetchGroups, fetchCounterAgentsWithoutGroup, searchText])

  const handleDeleteCounterAgent = useCallback(
    (record) => {
      Modal.confirm({
        title: "Удаление контрагента",
        content: `Вы уверены, что хотите удалить контрагента "${record.name}"?`,
        okText: "Да",
        okType: "danger",
        cancelText: "Нет",
        onOk: async () => {
          try {
            setLoading(true)
            const response = await axios_instance.delete(
              `/counterparty/delete/${record.id}`
            )
            if (response.status === 200) {
              if (searchText) {
                await fetchCounterAgentsWithSearch(searchText)
              } else {
                await fetchCounterAgentsWithoutGroup()
                if (record.group_id) {
                  setGroupCounterAgents((prev) => {
                    const updatedGroupCounterAgents = { ...prev }
                    updatedGroupCounterAgents[record.group_id] =
                      updatedGroupCounterAgents[record.group_id]?.filter(
                        (agent) => agent.id !== record.id
                      )
                    return updatedGroupCounterAgents
                  })
                }
              }
            } else {
             
            }
          } catch (error) {
           
          } finally {
            setLoading(false)
          }
        },
      })
    },
    [
      fetchCounterAgentsWithSearch,
      fetchCounterAgentsWithoutGroup,
      searchText,
      setGroupCounterAgents,
    ]
  )

  const handleDeleteGroup = useCallback(
    (record) => {
      Modal.confirm({
        title: "Удаление группы",
        content: `Вы уверены, что хотите удалить группу "${record.name}"?`,
        okText: "Да",
        okType: "danger",
        cancelText: "Нет",
        onOk: async () => {
          try {
            setLoading(true)
            const response = await axios_instance.delete(
              `/counterparty/group/delete/${record.id}`
            )
            if (response.status === 200) {
              await fetchGroups()
              await fetchCounterAgentsWithoutGroup()
              setGroupCounterAgents((prev) => {
                const updated = { ...prev }
                delete updated[record.id]
                return updated
              })
            } else {
            
            }
          } catch (error) {
           
          } finally {
            setLoading(false)
          }
        },
      })
    },
    [fetchGroups, fetchCounterAgentsWithoutGroup, setGroupCounterAgents]
  )

  const handleDelete = useCallback(
    (record) => {
      if (record.isGroup) {
        handleDeleteGroup(record)
      } else {
        handleDeleteCounterAgent(record)
      }
    },
    [handleDeleteCounterAgent, handleDeleteGroup]
  )

  const handleEdit = (record) => {
    if (record.isGroup) {
      handleEditGroup(record)
    } else {
      handleEditCounterAgent(record)
    }
  }

  const handleEditCounterAgent = (record) => {
    setEditingCounterAgent(record)
    setIsEditModalVisible(true)
  }

  const handleEditGroup = (record) => {
    setEditingGroup(record)
    setIsEditGroupModalVisible(true)
  }

  const handleEditModalOk = async () => {
    try {
      setLoading(true)
      const response = await axios_instance.put(
        `/counterparty/update/${editingCounterAgent.id}`,
        editingCounterAgent
      )
      if (response.status === 200) {
      

        if (searchText) {
          await fetchCounterAgentsWithSearch(searchText)
        } else {
          await fetchCounterAgentsWithoutGroup()
          if (editingCounterAgent.group_id) {
            setGroupCounterAgents((prev) => ({
              ...prev,
              [editingCounterAgent.group_id]: prev[
                editingCounterAgent.group_id
              ]?.map((agent) =>
                agent.id === editingCounterAgent.id
                  ? { ...agent, ...editingCounterAgent }
                  : agent
              ),
            }))
          }
        }

        setIsEditModalVisible(false)
        setEditingCounterAgent(null)
      } else {

      }
    } catch (error) {
     
    } finally {
      setLoading(false)
    }
  }

  const handleEditGroupModalOk = async () => {
    try {
      setLoading(true)
      const response = await axios_instance.put(
        `/counterparty/group/update/${editingGroup.id}`,
        { group_name: editingGroup.name }
      )
      if (response.status === 200) {
        message.success(`Группа ${editingGroup.name} успешно обновлена`)
        await fetchGroups()
        await fetchCounterAgentsWithoutGroup()
        setIsEditGroupModalVisible(false)
        setEditingGroup(null)
      } else {
        message.error("Ошибка при обновлении группы.")
      }
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response
        if (status === 400) {
          if (data.detail === "Название группы не может быть пустым") {
            message.error("Название группы не может быть пустым")
          } else if (
            data.detail ===
            "Название группы слишком длинное (максимальная длина - 255 символов)"
          ) {
            message.error(
              "Название группы слишком длинное (максимальная длина - 255 символов)"
            )
          } else if (
            data.detail === "Группа с таким названием уже существует"
          ) {
            message.error("Группа с таким названием уже существует")
          } else {
            message.error(data.detail)
          }
        } else if (status === 500) {
          message.error(
            "Произошла ошибка при обновлении группы. Пожалуйста, попробуйте позже. Обратитесь к администратору, если ошибка повторяется."
          )
        } else {
          message.error("Неизвестная ошибка. Пожалуйста, попробуйте позже.")
        }
      } else {
        message.error("Ошибка при обновлении группы.")
      }
    } finally {
      setLoading(false)
    }
  }
  const handleEditModalCancel = () => {
    setIsEditModalVisible(false)
    setEditingCounterAgent(null)
  }

  const handleEditGroupModalCancel = () => {
    setIsEditGroupModalVisible(false)
    setEditingGroup(null)
  }

  const handleEditInputChange = (field, value) => {
    setEditingCounterAgent((prev) => ({ ...prev, [field]: value }))
  }

  const handleEditGroupInputChange = (value) => {
    setEditingGroup((prev) => ({ ...prev, name: value }))
  }

  const columns = [
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: "ИНН",
      dataIndex: "inn",
      key: "inn",
      width: 150,
      render: (inn, record) => (record.isGroup ? null : inn),
    },
    {
      title: "Полное наименование",
      dataIndex: "fullName",
      key: "fullName",
      width: 300,
      render: (fullName, record) => (record.isGroup ? null : fullName),
    },
    {
      title: "Тег",
      dataIndex: "tag",
      key: "tag",
      width: 150,
      render: (tag, record) =>
        record.isGroup
          ? null
          : tag && (
              <Tag
                bordered={false}
                style={{
                  backgroundColor: "transparent",
                  color: "var(--text-basic)",
                  fontSize: "inherit",
                }}>
                {tag}
              </Tag>
            ),
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (status, record) =>
        record.isGroup
          ? null
          : status && (
              <Tag color={statusColors[status]}>{status.toUpperCase()}</Tag>
            ),
    },
    {
      title: "Действия",
      key: "actions",
      width: 120,
      render: (_, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            style={{ backgroundColor: "transparent", border: "none" }}
            onClick={() => handleEdit(record)}
          />
          <Button
            danger
            icon={<DeleteOutlined />}
            style={{ backgroundColor: "transparent", border: "none" }}
            onClick={() => handleDelete(record)}
          />
        </Space>
      ),
    },
  ]

  const components = {
    body: {
      row: DraggableRow,
    },
  }

  const handleDrop = useCallback(
    async (item, target) => {

      try {
        if (item.isGroup && !item.inn) {
          const newParentId = target.id === null ? null : target.id

          item.parent_id = newParentId
          const response = await axios_instance.put(
            `/counterparty/group/update/${item.id}`,
            {
              parent_id: newParentId,
            }
          )
          if (response.status === 200) {
            message.success(
              `Группа  успешно перенесена`
            )

          } else {

          }
        } else if (item.inn && target.isGroup) {
          const response = await axios_instance.put(
            `/counterparty/update/${item.id}`,
            {
              group_id: target.id,
            }
          )
          if (response.status === 200) {
            message.success(
              `Контрагент успешно перенесен в группу`
            )

            setGroupCounterAgents((prev) => {
              let updated = { ...prev }

              // Удаляем контрагента из всех групп, кроме целевой
              Object.keys(updated).forEach((groupKey) => {
                if (groupKey !== target?.id?.toString()) {
                  updated[groupKey] = updated[groupKey].filter(
                    (contragent) => contragent.id !== item.id
                  );
                }
              });

              // Добавляем контрагента в целевую группу
              if (target.id && updated[target.id]) {
                updated[target.id].push({ ...item, group_id: target.id })
              } else if (target.id) {
                updated[target.id] = [{ ...item, group_id: target.id }]
              }
              return updated
            })
            item.group_id = target.id
          } else {

          }
        } else {
          return
        }

        await fetchGroups()
        await fetchCounterAgentsWithoutGroup()
        if (target.id) {
          await fetchCounterAgentsByGroup(target.id)
        }
      } catch (error) {

      }
    },
    [fetchCounterAgentsByGroup, fetchGroups, fetchCounterAgentsWithoutGroup]
  )
  const [{ isOver }, drop] = useDrop(() => ({
    accept: [ItemTypes.COUNTER_AGENT, ItemTypes.GROUP],
    drop: (item, monitor) => {
      const didDrop = monitor.didDrop()
      if (didDrop) {
        return
      }

      const tableRect = document
        .querySelector(".ant-table-tbody")
        ?.getBoundingClientRect()
      const clientOffset = monitor.getClientOffset()

      if (!tableRect || !clientOffset) {
        return
      }

      const isOverTable =
        clientOffset.x >= tableRect.left &&
        clientOffset.x <= tableRect.right &&
        clientOffset.y >= tableRect.top &&
        clientOffset.y <= tableRect.bottom

      if (!isOverTable) {
        handleDrop(item, { id: null, isGroup: true })
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }))

  return (
    <Spin spinning={loading}>
      <div
        ref={drop}
        style={{
          padding: "20px",
          margin: "0 auto",
          minHeight: "calc(100vh - 110px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minWidth: "1100px",
          backgroundColor: "inherit",
        }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Title level={3}>КОНТРАГЕНТЫ</Title>
        </div>
        <Space style={{ marginBottom: 16 }}>
          <Input
            placeholder="Поиск..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ width: "300px" }}
            prefix={<SearchOutlined />}
          />
          <Button type="primary" onClick={() => navigate("add")}>
            Новый контрагент
          </Button>
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            Новая группа
          </Button>
        </Space>
        <DndProvider backend={HTML5Backend}>
          <Table
            columns={columns}
            dataSource={prepareDataForTable(
              groups,
              expandedKeys,
              searchText,
              noGroupCounterAgents,
              groupCounterAgents,
              counterAgentsWithSearch
            )}
            components={components}
            pagination={false}
            bordered
            style={{ flexGrow: 1 }}
            tableLayout="fixed"
            onExpand={onExpand}
            expandedRowKeys={expandedKeys}
            rowClassName={(record) =>
              record.inn ? "draggable-row" : "non-draggable-row"
            }
            expandable={{
              rowExpandable: (record) => (searchText ? false : true),
              expandIcon: ({ expanded, onExpand, record }) => {
                if (!record.inn) {
                  return (
                    <span
                      type="text"
                      onClick={(e) => onExpand(record, e)}
                      style={{
                        color: "var(--text-basic)",
                        width: "max-content",
                        cursor: "pointer",
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        verticalAlign: "middle",
                        gap: "10px",
                        marginRight: "10px",
                      }}>
                      <img
                        src={chevron}
                        alt=""
                        style={{
                          width: "24px",
                          height: "24px",
                          transform: expanded
                            ? "rotate(-90deg)"
                            : "rotate(0deg)",
                        }}
                      />
                      <img
                        src={folder}
                        alt=""
                        style={{ width: "24px", height: "24px" }}
                      />
                    </span>
                  )
                } else {
                  return (
                    <span
                      type="text"
                      style={{
                        color: "var(--text-basic)",
                        width: "max-content",

                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "100%",
                        verticalAlign: "middle",
                        gap: "10px",
                        marginRight: "10px",
                      }}>
                      <div alt="" style={{ width: "24px", height: "24px" }} />
                    </span>
                  )
                }
              },
            }}
            onRow={(record, index) => {
              return {
                index,
                moveRow,
                id: record.id,
                handleDrop,
                parent_id: record.parent_id,
                ...record,
              }
            }}
          />
        </DndProvider>

        <CounterAgentGroupCreateModal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          fetchGroups={fetchGroups}
        />

        <Modal
          title="Редактировать контрагента"
          visible={isEditModalVisible}
          onOk={handleEditModalOk}
          onCancel={handleEditModalCancel}
          okText="Сохранить"
          cancelText="Отмена">
          <Form layout="vertical">
            <Form.Item label="ИНН">
              <Input
                value={editingCounterAgent?.inn}
                onChange={(e) => handleEditInputChange("inn", e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Полное наименование">
              <Input
                value={editingCounterAgent?.fullName}
                onChange={(e) =>
                  handleEditInputChange("full_name", e.target.value)
                }
              />
            </Form.Item>
            <Form.Item label="Тег">
              <Input
                value={editingCounterAgent?.tag}
                onChange={(e) => handleEditInputChange("tag", e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Статус">
              <Select
                value={editingCounterAgent?.status}
                onChange={(value) => handleEditInputChange("status", value)}>
                <Option value="активный">активный</Option>
                <Option value="неактивный">неактивный</Option>
                <Option value="новый">новый</Option>
                <Option value="заблокирован">заблокирован</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>

        <Modal
          title="Редактировать группу"
          visible={isEditGroupModalVisible}
          onOk={() => {
            if (editingGroup?.name) {
              handleEditGroupModalOk();
            } else {
              message.error("Пожалуйста, введите наименование группы!");
            }
          }}
          onCancel={handleEditGroupModalCancel}
          okText="Сохранить"
          cancelText="Отмена">
          <Form layout="vertical">
            <Form.Item
              label="Наименование"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите наименование!",
                },
              ]}>
              <Input
                value={editingGroup?.name}
                onChange={(e) =>  handleEditGroupInputChange(e.target.value)}
              />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Spin>
  )
}

export default ConterAgentsTable
