import React, { useState } from "react";
import { Button, Input, Space, Table } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { filteredData, validateData } from "../../features/DataSearcherUtils";
import BankAccountsModal from "../bankAccountsModal/bankAccountsModal";

const BankAccountsTabContent = () => {
  const [searchText, setSearchText] = useState("");

  const data = [
    {
      key: "1",
      name: "Москва",
      children: [
        {
          key: "5",
          name: "ПРОМСВЯЗЬБАНК ПАО",
          bik: "044525555",
          accountNumber: "30223810500000000555",
          currency: "РУБ",
          openDate: "01.01.2023",
          closeDate: "",
        },
        {
          key: "6",
          name: "СБЕРБАНК ПАО",
          bik: "044525225",
          accountNumber: "40702810138000057472",
          currency: "РУБ",
          openDate: "01.01.2022",
          closeDate: "31.12.2023",
        },
      ],
    },
  ];
  const [isModalVisible, setIsModalVisible] = useState(false);

  const columns = [
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "БИК",
      dataIndex: "bik",
      key: "bik",
    },
    {
      title: "Номер счета",
      dataIndex: "accountNumber",
      key: "accountNumber",
    },
    {
      title: "Валюта",
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: "Открыт",
      dataIndex: "openDate",
      key: "openDate",
    },
    {
      title: "Закрыт",
      dataIndex: "closeDate",
      key: "closeDate",
    },
    {
      title: "Действия",
      key: "actions",
      width: "5%",
      render: (_, record) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            style={{ backgroundColor: "transparent", border: "none" }}
          />
          <Button
            danger
            icon={<DeleteOutlined />}
            style={{ backgroundColor: "transparent", border: "none" }}
          />
        </Space>
      ),
    },
  ];
  return (
    <div className="content-wrapper">
      <Space style={{ marginBottom: 16 }}>
        <Input
          placeholder="Поиск по названию"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: "300px" }}
          prefix={<SearchOutlined />}
        />
        <Button type="primary" onClick={() => setIsModalVisible(true)}>
          Новый банк
        </Button>
        <Button type="primary">Новая группа</Button>
      </Space>

      <Table
        columns={columns}
        dataSource={filteredData(validateData(data), searchText)}
        pagination
        bordered
        style={{ flexGrow: 1 }}
      />

      {
        <BankAccountsModal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
        />
      }
    </div>
  );
};

export default BankAccountsTabContent;
