import React, { useState } from "react";
import { Table, Input, Button, Space, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { filteredData, validateData } from "../../features/DataSearcherUtils";

const { Title } = Typography;

const CountriesClassifier = () => {
  const [searchText, setSearchText] = useState("");

  const data = [
    {
      key: 1,
      name: "Российская Федерация",
      fullName: "Российская Федерация",
      alpha2: "RU",
      alpha3: "RUS",
    },
    {
      key: 2,
      name: "Соединенные Штаты Америки",
      fullName: "Соединенные Штаты Америки",
      alpha2: "US",
      alpha3: "USA",
    },
    {
      key: 3,
      name: "Германия",
      fullName: "Федеративная Республика Германия",
      alpha2: "DE",
      alpha3: "DEU",
    },
    {
      key: 4,
      name: "Франция",
      fullName: "Французская Республика",
      alpha2: "FR",
      alpha3: "FRA",
    },
    {
      key: 5,
      name: "Япония",
      fullName: "Япония",
      alpha2: "JP",
      alpha3: "JPN",
    },
    {
      key: 6,
      name: "Китай",
      fullName: "Китайская Народная Республика",
      alpha2: "CN",
      alpha3: "CHN",
    },
    {
      key: 7,
      name: "Великобритания",
      fullName: "Соединенное Королевство Великобритании и Северной Ирландии",
      alpha2: "GB",
      alpha3: "GBR",
    },
    {
      key: 8,
      name: "Италия",
      fullName: "Италия",
      alpha2: "IT",
      alpha3: "ITA",
    },
    {
      key: 9,
      name: "Бразилия",
      fullName: "Бразилия Федеративная Республика",
      alpha2: "BR",
      alpha3: "BRA",
    },
    {
      key: 10,
      name: "Канада",
      fullName: "Канада",
      alpha2: "CA",
      alpha3: "CAN",
    },
    {
      key: 11,
      name: "Индия",
      fullName: "Республика Индия",
      alpha2: "IN",
      alpha3: "IND",
    },
    {
      key: 12,
      name: "Австралия",
      fullName: "Содружество Австралии",
      alpha2: "AU",
      alpha3: "AUS",
    },
    {
      key: 13,
      name: "Испания",
      fullName: "Испания Королевство",
      alpha2: "ES",
      alpha3: "ESP",
    },
    {
      key: 14,
      name: "Мексика",
      fullName: "Мексиканские Соединенные Штаты",
      alpha2: "MX",
      alpha3: "MEX",
    },
    {
      key: 15,
      name: "Южная Корея",
      fullName: "Республика Корея",
      alpha2: "KR",
      alpha3: "KOR",
    },
    {
      key: 16,
      name: "Саудовская Аравия",
      fullName: "Королевство Саудовская Аравия",
      alpha2: "SA",
      alpha3: "SAU",
    },
    {
      key: 17,
      name: "Нидерланды",
      fullName: "Королевство Нидерланды",
      alpha2: "NL",
      alpha3: "NLD",
    },
    {
      key: 18,
      name: "Швеция",
      fullName: "Королевство Швеция",
      alpha2: "SE",
      alpha3: "SWE",
    },
    {
      key: 19,
      name: "Швейцария",
      fullName: "Швейцарская Конфедерация",
      alpha2: "CH",
      alpha3: "CHE",
    },
    {
      key: 20,
      name: "Южная Африка",
      fullName: "Республика Южная Африка",
      alpha2: "ZA",
      alpha3: "ZAF",
    },
  ];
  const columns = [
    {
      title: "Код",
      dataIndex: "key",
      key: "key",
      width: "10%",
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
      width: "35%",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Полное наименование",
      dataIndex: "fullName",
      key: "fullName",
      width: "35%",
    },
    {
      title: "Альфа-2",
      dataIndex: "alpha2",
      key: "alpha2",
      width: "10%",
    },
    {
      title: "Альфа-3",
      dataIndex: "alpha3",
      key: "alpha3",
      width: "10%",
    },
  ];

  return (
    <div className="content-wrapper">
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Title level={3}>ОБЩЕРОССИЙСКИЙ КЛАССИФИКАТОР СТРАН МИРА (ОКСМ)</Title>
      </div>
      <Space style={{ marginBottom: 16 }}>
        <Input
          placeholder="Поиск по названию"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: "300px" }}
          prefix={<SearchOutlined />}
        />
        <Button type="primary">Обновить классификатор</Button>
      </Space>

      <Table
        columns={columns}
        dataSource={filteredData(validateData(data), searchText)}
        bordered
        style={{ flexGrow: 1 }}
        tableLayout="fixed"
        pagination={false}
        scroll={{ y: 440 }}
      />
    </div>
  );
};

export default CountriesClassifier;
