import React from "react";
import { Input, Menu, Button, Avatar, Card } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useLocation, Link } from "react-router-dom";
import styles from "./AsideProfile.module.css";
import logo from "../../public/profile-logo-exm.svg";
import authStore from "../../store/AuthStore";
import { observer } from "mobx-react-lite";

export const Aside = observer(() => {
  const location = useLocation();

  const menuData = [
    { key: "request", label: "Заявки", path: "request" },
    { key: "orders", label: "Заказы", path: "orders" },
    { key: "tracking", label: "Отслеживание", path: "tracking" },
    {
      key: "sub1",
      label: "Справочники",
      children: [
        { key: "addresses", label: "Адреса", path: "directory/addresses" },
        { key: "banks", label: "Банки", path: "directory/banks" },
        {
          key: "counteragents",
          label: "Контрагенты",
          path: "directory/counteragents",
        },
        {
          key: "organizations",
          label: "Организации",
          path: "directory/organizations",
        },
        {
          key: "departments",
          label: "Подразделения",
          path: "directory/departments",
        },
        { key: "employees", label: "Сотрудники", path: "directory/employees" },
        {
          key: "classifier/countries",
          label: "Страны мира классификатор",
          path: "classifier/countries",
        },
        {
          key: "directory/countries",
          label: "Страны мира справочник",
          path: "directory/countries",
        },
      ],
    },
    { key: "analytics", label: "Аналитика", path: "analytics" },
    { key: "reports", label: "Отчеты", path: "reports" },
  ];

  // Разбейте меню на группы
  const menuGroups = [
    menuData.slice(0, 3), // "Заявки", "Заказы", "Отслеживание"
    [menuData[3]], // "Управление" с подменю
    menuData.slice(4), // "Аналитика", "Отчеты"
  ];

  const getMenuItems = (data) =>
    data.map((item) => {
      const menuItem = {
        key: item.key,
        label: item.path ? (
          <Link to={item.path}>{item.label}</Link>
        ) : (
          <span className={styles.menuItem}>{item.label}</span>
        ),
        className: styles.menuItem,
      };
      if (item.children) {
        menuItem.children = getMenuItems(item.children);
      }
      return menuItem;
    });
  const getSelectedKey = () => {
    const path = location.pathname.split("/").pop();
    const findKey = (data) => {
      for (const item of data) {
        if (item.path === path) {
          return item.key;
        }
        if (item.children) {
          const childKey = findKey(item.children);
          if (childKey) {
            return childKey;
          }
        }
      }
      return null;
    };
    return findKey(menuData);
  };
  const selectedKey = getSelectedKey();
  return (
    <aside className={styles.aside}>
      <Card className={styles.profileCard} bodyStyle={{ padding: 17 }}>
        <Card.Meta
          avatar={<Avatar src={logo} size={60} />}
          description={
            <>
              <p style={{ margin: 0, color: "#e4e4e4" }}>ООО “ПСГ”</p>
              <p style={{ margin: 0, color: "#e4e4e4" }}>Менеджер</p>
              <p style={{ margin: 0, color: "#e4e4e4" }}>
                {!authStore.user ? "Loading..." : authStore.user.first_name}
              </p>
            </>
          }
        />
        <Button type="primary" className={styles.profileButton}>
          Профиль
        </Button>
      </Card>
      <div className={styles.search}>
        <Input
          placeholder="Поиск по сайту"
          prefix={<SearchOutlined />}
          style={{ width: "90%" }}
        />
      </div>

      {menuGroups.map((group, index) => (
        <Menu
          key={index}
          mode="inline"
          selectedKeys={[selectedKey]}
          className={styles.menu}
          items={getMenuItems(group)}
        />
      ))}
    </aside>
  );
});
