import React from "react";
import { Input, Menu } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import "./Aside.css";

export const Aside = ({ menuItems }) => {
  const location = useLocation();

  const menuData = menuItems.map((item) => ({
    key: item.key,
    label: <Link to={item.path}>{item.label}</Link>,
  }));

  const selectedKey = menuItems.find(
    (item) => item.path === location.pathname
  )?.key;

  return (
    <aside className="aside">
      <div className="search">
        <Input
          placeholder="Поиск по сайту"
          prefix={<SearchOutlined />}
          style={{ width: "90%" }}
        />
      </div>
      <Menu
        mode="inline"
        selectedKeys={[selectedKey]}
        items={menuData}
        className="menu"
        style={{ borderInlineEnd: "none" }}
      />
    </aside>
  );
};
