import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../../public/sherpa_logo.svg";
import { Layout, Button } from "antd";
import LoginModal from "../../components/loginModal/LoginModal";
import authStore from "../../store/AuthStore";
import "./Header.css";
import { observer } from "mobx-react-lite";

export const Header = observer(() => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleLoginClick = () => {
    if (authStore.isAuth) {
      if (location.pathname === "/profile") {
        navigate("/");
      } else {
        navigate("/profile");
      }
    } else {
      setIsModalVisible(true);
    }
  };

  return (
    <Layout.Header className="header">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="Sherpa Global Logistic" />
        </Link>
      </div>
      <div className="login">
        <Button className={"logogin-button"} onClick={handleLoginClick}>
          {authStore.isAuth && location.pathname.startsWith("/profile")
            ? "На главную"
            : authStore.isAuth
            ? "Личный кабинет"
            : "Войти"}
        </Button>
        <Button onClick={() => authStore.logout()}>ВЫХОД</Button>
      </div>

      {/* Модальное окно для входа */}
      <LoginModal
        visible={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
        }}
      />
    </Layout.Header>
  );
});

export default Header;
