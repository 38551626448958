import React from "react";
import { Modal, Form, Input, Button, Row, Col } from "antd";

const CounterAgentAdressesTabContentModal = ({ visible, setVisible }) => {
  const [form] = Form.useForm();

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        console.log("Сохраненные значения:", values);
        setVisible(false);
        form.resetFields();
      })
      .catch((info) => {
        console.log("Ошибка в форме:", info);
      });
  };

  const handleBack = () => {
    setVisible(false);
    form.resetFields();
  };

  return (
    <>
      <Modal
        title={<div style={{ textAlign: "center", color: "black" }}>Адрес</div>}
        open={visible}
        onCancel={handleBack}
        footer={null}
        initialValues={{
          variant: "filled",
        }}
        layout={"vertical"}
        requiredMark={false}
      >
        <Form form={form} layout="vertical">
          <Row gutter={16} justify={"space-between"}>
            <Col span={12}>
              <Form.Item
                label="Тип адресса"
                name="addressType"
                rules={[{ required: true, message: "Введите тип адресса" }]}
              >
                <Input placeholder="Введите тип адресса" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Индекс"
                name="index"
                rules={[{ required: true, message: "Введите индекс" }]}
              >
                <Input placeholder="Введите индекс" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Страна"
                name="country"
                rules={[{ required: true, message: "Введите страну" }]}
              >
                <Input placeholder="Введите страну" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Регион"
                name="region"
                rules={[{ required: true, message: "Введите регион" }]}
              >
                <Input placeholder="Введите регион" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Город/поселение"
                name="city"
                rules={[
                  { required: true, message: "Введите город или поселение" },
                ]}
              >
                <Input placeholder="Введите город или поселение" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Район города"
                name="cityDistrict"
                rules={[{ required: true, message: "Введите район города" }]}
              >
                <Input placeholder="Введите район города" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Улица"
                name="street"
                rules={[{ required: true, message: "Введите улицу" }]}
              >
                <Input placeholder="Введите улицу" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                label="Дом"
                name="house"
                rules={[{ required: true, message: "Введите дом" }]}
              >
                <Input placeholder="Введите дом" />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label="Корпус" name="building">
                <Input placeholder="Введите корпус" />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="Квартира" name="apartment">
                <Input placeholder="Введите квартиру" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Адресная строка"
            name="addressLine"
            rules={[{ required: true, message: "Введите адресную строку" }]}
          >
            <Input placeholder="Введите адресную строку" />
          </Form.Item>
          <Row justify={"center"}>
            <Form.Item>
              <Button onClick={handleBack} style={{ marginRight: "8px" }}>
                Назад
              </Button>
              <Button type="primary" onClick={handleSave}>
                Сохранить
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default CounterAgentAdressesTabContentModal;
