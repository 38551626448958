import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ links }) => {
  return (
    <div className="breadcrumb-container">
      <span className="breadcrumb">
        {links.map((link, index) => (
          <span key={index}>
            <Link to={link.href} className="breadcrumb-link">
              {link.label}
            </Link>
            {index < links.length - 1 && " > "}
          </span>
        ))}
      </span>
    </div>
  );
};

export default Breadcrumb;
