import React from "react";
import { Tabs } from "antd";
import styles from "./counterAgentRequisites.module.css";
import CounterAgentDetailsForm from "../counterAgentDetailsForm/CounterAgentDetailsForm";
import BankAccountsTabContent from "../BankAccountsTabContent/BankAccountsTabContent";
import CounterAgentAdressesTabContent from "../counterAgentAdressesTabContent/CounterAgentAdressesTabContent";

const ContractsTabContent = () => <p>Содержимое вкладки "Договоры"</p>;
const DocumentsTabContent = () => <p>Содержимое вкладки "Документы"</p>;
const ContactsTabContent = () => <p>Содержимое вкладки "Контакты"</p>;
const FilesTabContent = () => <p>Содержимое вкладки "Файлы"</p>;
const items = [
  {
    key: "1",
    label: "Реквизиты",
    children: <CounterAgentDetailsForm />,
  },
  {
    key: "2",
    label: "Документы",
    children: <DocumentsTabContent />,
  },
  {
    key: "3",
    label: "Договоры",
    children: <ContractsTabContent />,
  },
  {
    key: "4",
    label: "Банковские счета",
    children: <BankAccountsTabContent />,
  },
  {
    key: "5",
    label: "Адреса",
    children: <CounterAgentAdressesTabContent />,
  },
  {
    key: "6",
    label: "Контакты",
    children: <ContactsTabContent />,
  },
  {
    key: "7",
    label: "Файлы",
    children: <FilesTabContent />,
  },
];

const CounterAgentRequisites = () => {
  return (
    <div
      style={{
        backgroundColor: "var(--psg-pale-gray)",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h3 className={styles.title}>КАРТОЧКА КОНТРАГЕНТА</h3>
      <div className={styles.container}>
        <Tabs
          defaultActiveKey="1"
          centered={true}
          type={"card"}
          items={items}
          size={"large"}
        />
      </div>
    </div>
  );
};

export default CounterAgentRequisites;
