import axios from "axios";


const axios_instance = axios.create({
  headers: { "Content-Type": "application/json" },
  baseURL: 'https://sherpa.services/proxy',
  withCredentials: true,
});

export default axios_instance;

