import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import MainPage from "../pages/mainPage/MainPage";
import DeliveryPage from "../pages/delivery/DeliveryPage";
import ProfilePage from "../pages/profilePage/ProfilePage";
import authStore from "../store/AuthStore";
import { useEffect, useState } from "react";
import ProtectedRoute from "./ProtectedRoute";
import { observer } from "mobx-react-lite";
import { ConfigProvider } from "antd";
import ru_RU from "antd/locale/ru_RU";
const AppRouter = observer(() => {
  const [isAuthChecked, setIsAuthChecked] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      await authStore.checkIsAuthenticated();
      setIsAuthChecked(true);
    };
    checkAuth();
  }, []);

  if (!isAuthChecked) {
    return;
  }

  return (
    <ConfigProvider
      locale={ru_RU}
      theme={{
        token: {
          controlHeight: 40,
          colorBorder: "var(--psg-gray-disabled)",
          colorText: "var(--text-gray)",
          colorTextDescription: "var(--text-gray)",
          colorTextPlaceholder: "var(--text-gray)",
        },
        components: {
          Form: {
            itemMarginBottom: 10,
            verticalLabelPadding: "0 0 3px",
          },
          Tabs: {
            colorBgContainer: "white",
            cardBg: "var(--psg-gray-disabled)",
            itemColor: "var(--text-basic)",
            itemHoverColor: "none",
            itemActiveColor: "none",
            itemSelectedColor: "var(--text-basic)",
            BorderRadius: 4,
          },
          Table: {
            colorText: "var(--text-basic)",
            headerColor: "var(--text-basic)",
          },
          DatePicker: {
            controlHeight: "40",
          },
          Menu: {
            itemHeight: 40,
          },
        },
      }}
    >
      <Router>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="delivery" element={<DeliveryPage />} />
          <Route
            path="profile/*"
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </ConfigProvider>
  );
});

export default AppRouter;
