import { makeAutoObservable } from "mobx";
import axiosInstance from "../requests/config_defaul";
import { message } from "antd";

class CounterAgentRequisitesStore {
  inn = "";
  name = "";
  fullName = "";
  kpp = "";
  country = "РОССИЯ";
  regNumber = "";
  ogrn = "";
  phone = "";
  email = "";
  managerName = "";
  managerPhone = "";
  managerEmail = "";
  accountantName = "";
  accountantPhone = "";
  accountantEmail = "";
  legalAddress = "";
  actualAddress = "";
  typeCounterAgent = "";

  constructor() {
    makeAutoObservable(this);
  }

  // Универсальный метод для обновления поля
  setField = (field, value) => {
    this[field] = value;
  };

  // Новый метод для отправки формы
  submitForm = async () => {
    const payload = {
      "inn": this.inn,
      "name": this.name,
      "full_name": this.fullName,
      "legal_individual": this.typeCounterAgent,
      "kpp": this.kpp,
      "regNumber": this.regNumber,
      "ogrn": this.ogrn,
      "contact": {
        "phone": this.phone,
        "email": this.email
      },
    };

    if (this.managerName.split(" ")[0] && this.managerName.split(" ")[1]) {
      payload.general_director_data = {
        "role": "Генеральный директор",
        "person": {
          "contact": {
            "phone": this.managerPhone,
            "email": this.managerEmail
          },
          "last_name": this.managerName.split(" ")[0],
          "first_name": this.managerName.split(" ")[1],
        }
      };
    }

    if (this.accountantName.split(" ")[0] && this.accountantName.split(" ")[1]) {
      payload.chief_accountant_data = {
        "role": "Главный бухгалтер",
        "person": {
          "contact": {
            "phone": this.accountantPhone,
            "email": this.accountantEmail,
          },
          "last_name": this.accountantName.split(" ")[0],
          "first_name": this.accountantName.split(" ")[1],
        }
      };
    }

    try {
      const response = await axiosInstance.post("/counterparty/create", payload);
      if (response.status >= 200 && response.status < 300) {
        message.success("Данные успешно сохранены!");
      } else {
        message.error("Ошибка при сохранении данных.");
        console.error("Server error:", response.data);
      }
    } catch (error) {
      message.error("Произошла ошибка при отправке формы.");
      console.error("Submission error:", error);
    }
  };
}

const counterAgentRequisitesStore = new CounterAgentRequisitesStore();
export default counterAgentRequisitesStore;