import React from "react";
import { observer } from "mobx-react-lite";
import { Form, Row, Col } from "antd";
import ShippingFormStore from "../../store/ShippingFormStore";
import RouteBlock from "./routeBlock/RouteBlock";

const RouteSection = observer(() => {
  return (
    <>
      <h2>ОЗНАКОМИТЬСЯ С ПРЕДЛОЖЕНИЯМИ ПО ДОСТАВКЕ</h2>
      <div className="section">
        <h3>Маршрут отправления</h3>
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <RouteBlock
                title="Откуда"
                country={ShippingFormStore.departureCountry}
                setCountry={(value) =>
                  ShippingFormStore.setField("departureCountry", value)
                }
                city={ShippingFormStore.departureCity}
                adressName={ShippingFormStore.departureAddressName}
                setCity={(value) =>
                  ShippingFormStore.setField("departureCity", value)
                }
                setAdressName={(value) =>
                  ShippingFormStore.setField("departureAddressName", value)
                }
                setAdress={(value) =>
                  ShippingFormStore.setField("departureAddress", value)
                }
              />
            </Col>
            <Col span={12}>
              <RouteBlock
                title="Куда"
                country={ShippingFormStore.destinationCountry}
                setCountry={(value) =>
                  ShippingFormStore.setField("destinationCountry", value)
                }
                city={ShippingFormStore.destinationCity}
                adressName={ShippingFormStore.destinationAddressName}
                setCity={(value) =>
                  ShippingFormStore.setField("destinationCity", value)
                }
                setAdressName={(value) =>
                  ShippingFormStore.setField("destinationAddressName", value)
                }
                setAdress={(value) =>
                  ShippingFormStore.setField("destinationAddress", value)
                }
              />
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
});

export default RouteSection;