import axios from 'axios';

const BASE_URL = "https://sherpa.services/proxy";

export async function DeliveryRequestBase(payload) {
  const url = `${BASE_URL}/calculating`;
  console.log('sended');
  try {
    const response = await axios.post(url, payload, {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
    });

    return response.data; // Axios автоматически парсит ответ в JSON
  } catch (error) {
    console.error("Ошибка при выполнении запроса:", error);
    throw error; // Пробрасываем ошибку для обработки в вызывающем коде
  }
}