import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { Input, Dropdown, Menu, Spin } from 'antd';
import axios_instance from '../requests/config_defaul';
import { debounce } from 'lodash';

export const useGeoSearcher = ({ style, className, setAdress, setAdressName, adressName }) => {
  const [q, setQ] = useState(adressName); 
  const [suggestions, setSuggestions] = useState([]); 
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false); 
  const [value, setValue] = useState(null); 
  const containerRef = useRef(null); 
  const abortController = useRef(new AbortController()); 
  const [parentsCity, setParentsCity] = useState([]);
  const [loading, setLoading] = useState(false);

  const geoguidSearch = useCallback(debounce(async () => {
    if (!q) return;

    abortController.current.abort();
    abortController.current = new AbortController();
    setLoading(true);

    try {
      const response = await axios_instance.get(`/geo_object/list?contentType=city&q=${q}`, {
        signal: abortController.current.signal,
      });
      setSuggestions(response?.data?.results || []);
      setIsSuggestionsVisible(true);
    } catch (error) {
      if (error.name !== 'CanceledError') {
        console.error(error);
      }
    } finally {
      setLoading(false);
    }
  }, 300), [q]);

  useEffect(() => {
    geoguidSearch();
    return () => {
      geoguidSearch.cancel();
    };
  }, [geoguidSearch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsSuggestionsVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const menu = useMemo(() => (
    <Menu style={{ maxHeight: '200px', overflow: 'auto' }}>
      {loading ? (
        <div style={{ padding: '10px', textAlign: 'center' }}>
          <Spin size="small" />
        </div>
      ) : (
        suggestions.map((sugg) => (
          <Menu.Item
            key={sugg.id}
            onClick={() => handleSelect(sugg)}
          >
            {sugg.name} - {sugg.full_name}
          </Menu.Item>
        ))
      )}
    </Menu>
  ), [loading, suggestions]);

  const handleSelect = useCallback((item) => {
    setValue(item);
    setQ(item.full_name);
    setIsSuggestionsVisible(false);
    setAdress(item.id);
    setAdressName(item.full_name);
    setParentsCity(item.name);
  }, []);

  return {
    searcher: (
      <div>
        <Dropdown
          overlay={menu}
          open={isSuggestionsVisible && suggestions.length > 0}
          trigger={['click']}
        >
          <div>
            <Input
              placeholder="Укажите геолокацию"
              value={q}
              onChange={(e) => setQ(e.target.value)}
              onFocus={() => {
                if (q) setIsSuggestionsVisible(true);
              }}
            />
          </div>
        </Dropdown>
      </div>
    ),
    geo_object: value,
    setValue: setValue,
    parentsCity: parentsCity,
  };
};