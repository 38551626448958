import React from "react";
import { Tabs } from "antd";
import styles from "../employeesDetailForm/EmployeesDetailForm.module.css";
import EmployeesDetailForm from "../employeesDetailForm/EmployeesDetailForm";

const DocumentsTabContent = () => <p>Содержимое вкладки "Документы"</p>;
const AddressesTabContent = () => <p>Содержимое вкладки "Адреса"</p>;
const ContactsTabContent = () => <p>Содержимое вкладки "Контакты"</p>;
const items = [
  {
    key: "1",
    label: "Главное",
    children: <EmployeesDetailForm />,
  },
  {
    key: "2",
    label: "Документы",
    children: <DocumentsTabContent />,
  },
  {
    key: "3",
    label: "Контакты",
    children: <ContactsTabContent />,
  },
  {
    key: "4",
    label: "Адреса",
    children: <AddressesTabContent />,
  },
];

const EmployeesRequisites = () => {
  return (
    <div
      style={{
        backgroundColor: "var(--psg-pale-gray)",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <h3 className={styles.title}>КАРТОЧКА СОТРУДНИКА</h3>
      <div className={styles.container}>
        <Tabs
          defaultActiveKey="1"
          centered={false}
          type={"card"}
          items={items}
          size={"large"}
        />
      </div>
    </div>
  );
};

export default EmployeesRequisites;
