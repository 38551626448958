import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Select,
  Modal,
  message,
} from 'antd';
import axios_instance from '../../requests/config_defaul';

const CounterAgentGroupCreateModal = ({ visible, onCancel, fetchGroups }) => {
  const [form] = Form.useForm();
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axios_instance.get('/counterparty/group/all/flat');
        setGroups(response.data);
      } catch (error) {
        console.error('Error fetching groups:', error);
      }
    };

    fetchGroups();
  }, []);

  const handleSubmit = async (values) => {
    try {
      const response = await axios_instance.post('/counterparty/group/create', {
        group_name: values.name,
        parent_id: values.parentGroup
      });
      if (response.status === 201 || response.status === 200) {
        message.success('Группа успешно создана!');
        form.resetFields();
        fetchGroups(); 
        onCancel();
      } else {
        message.error('Ошибка при создании группы.');
      }
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          if (data.detail === "Название группы не может быть пустым") {
            message.error('Название группы не может быть пустым');
          } else if (data.detail === "Название группы слишком длинное (максимальная длина - 255 символов)") {
            message.error('Название группы слишком длинное (максимальная длина - 255 символов)');
          } else if (data.detail === "Группа с таким названием уже существует") {
            message.error('Группа с таким названием уже существует');
          } else {
            message.error(data.detail);
          }
        } else if (status === 500) {
          message.error('Произошла ошибка при создании группы. Пожалуйста, попробуйте позже. Обратитесь к администратору, если ошибка повторяется.');
        } else {
          message.error('Неизвестная ошибка. Пожалуйста, попробуйте позже.');
        }
      } else {
        message.error('Ошибка при создании группы.');
      }
      console.error('Error creating group:', error);
    }
  };

  return (
    <Modal
      open={visible}
      title="Группа контрагентов"
      onCancel={onCancel}
      footer={null}
      width={560}
      height={500}
      transitionName=""
    >
      <Form
        form={form}
        initialValues={{
          variant: 'filled',
        }}
        layout={"vertical"}
        requiredMark={false}
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Наименование"
          name="name"
          rules={[{ required: true, message: 'Пожалуйста, введите наименование!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Родительская группа"
          name="parentGroup"
        >
          <Select
            options={groups.map(group => ({ value: group.id, label: group.group_name }))}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CounterAgentGroupCreateModal;